import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { getPopbillListAsync } from "src/api/popbill/popbill-api";
import { PopbillQueryParams } from "src/api/popbill/popbill-types";
import { Order } from "src/api/public-types";
import { BaseButton, BaseInput, BaseSelect, BaseTable } from "src/components";
import { PagePath } from "src/pages/product/details";
import PopbillColumns from "./PopbillColumns";
import { PopbillSearchOptions, UnionPopbillSearchType } from "../popbill-constant";

const PopbillList = () => {
  const navigate = useNavigate();

  const [params, setParams] = useState<PopbillQueryParams>({
    page: 0,
    size: 20,
    sort: {
      orders: [{ property: "pbBizId", direction: "DESC" }],
    },
  });

  // 검색어 입력값을 위한 별도의 state
  const [searchForm, setSearchForm] = useState<{
    searchType: UnionPopbillSearchType;
    searchValue: string;
  }>({
    searchType: "ALL",
    searchValue: "",
  });

  // 검색 실행 함수
  const handleSearch = () => {
    setParams({
      ...params,
      page: 0,
      searchType: searchForm.searchType || "",
      searchValue: searchForm.searchValue,
    });
  };

  const { executeAsync: getPopbillList } = useApiOperation(getPopbillListAsync);

  const { data } = useQuery({
    queryKey: ["popbillList", params],
    queryFn: () => getPopbillList(params),
    select: (response) => ({
      list: response.data.data.content,
      pageMeta: response.data.meta.pageMeta,
    }),
  });

  return (
    <div className="page-product-list">
      <div className="contents-container__search-wrap">
        <div className="left-area">
          <div className="minmax150 mr8">
            <BaseSelect
              placeholder="구분"
              stateOptions={PopbillSearchOptions}
              value={searchForm.searchType}
              setStateValue={(searchType: UnionPopbillSearchType) =>
                setSearchForm((prev) => ({ ...prev, searchType }))
              }
            />
          </div>
          <div className="minmax220">
            <BaseInput
              value={searchForm.searchValue}
              className="mr16"
              type="text"
              placeholder="검색어를 입력해주세요."
              onChange={(searchValue: string) =>
                setSearchForm((prev) => ({ ...prev, searchValue }))
              }
              onKeyUp={handleSearch}
              onSearchClick={handleSearch}
            />
          </div>
        </div>
        <div className="right-area">
          <BaseButton title="+ 등록" onClick={() => navigate(PagePath.popbill.form)} />
        </div>
      </div>
      <BaseTable
        data={data?.list || []}
        columns={PopbillColumns}
        pageIndex={Number(params?.page || 0)}
        totalPages={data?.pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          setParams({ ...params, page });
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={["rentalCost", "image"]}
        setOrders={(orders?: Array<Order>) => {
          setParams({ ...params, sort: { orders } });
        }}
      />
    </div>
  );
};

export default PopbillList;
