import moment from "moment";
import React from "react";
import { ProductModel } from "src/api/product/product-types";
import { numberToStringWithComma, YmdFormat } from "src/utils";

type Props = {
  detail: ProductModel;
};
/* 
  Full Court
 */
const FullCourtDetail = ({ detail }: Props) => {
  const maintainArray = detail?.productMaintenanceFeeList;

  return (
    <article className="contents-container__divide-top pb30">
      <div className="contents-container__sub-title">
        <h2>Full Court</h2>
      </div>
      {/* 계약기간 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>계약기간</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <span className="font14 mr10">최소</span>
            <div>
              <span>{detail.minLeasePeriod}</span>
            </div>
            <span className="font14 mx10">개월 ~ 최대</span>
            <div>
              <span>{detail.maxLeasePeriod}</span>
            </div>
            <span className="font14 ml10">개월</span>
          </div>
        </div>
      </section>
      {/* 입주 가능일 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>입주 가능일</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            {detail.isRightAwayMoveIn ? (
              <span>즉시</span>
            ) : (
              <span>{moment(detail.moveInAvailDate).format(YmdFormat.YYYY_MM_DD)}</span>
            )}
          </div>
        </div>
      </section>
      {/* 보증금 */}
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p>보증금</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div className="">보증금 (원)</div>
                </th>
                <th>
                  <div className="">계약금 (원)</div>
                </th>
                <th>
                  <div className="minmax420">잔금 (원) / 일자</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span>{numberToStringWithComma(Number(detail.deposit))}</span>
                  </div>
                </td>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span>{numberToStringWithComma(Number(detail.earnest))}</span>
                  </div>
                </td>
                <td>
                  <div className="flex-center-center no-wrap">
                    <span>
                      {numberToStringWithComma(Number(detail.deposit) - Number(detail.earnest))}
                    </span>{" "}
                    <span className="mx10"> / 계약 후</span>
                    <div className=" text-right">
                      <span>{detail.balanceFullPaymentDate}</span>
                    </div>
                    <span className="mx10">일 이내</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      {/* 보증금 할인 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>보증금 할인</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div className="">보증금 (원)</div>
                </th>
                <th>
                  <div className="">계약금 (원)</div>
                </th>
                <th>
                  <div className="minmax420">잔금 (원) / 일자</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="">{numberToStringWithComma(Number(detail.saleDeposit))}</span>
                  </div>
                </td>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="">{numberToStringWithComma(Number(detail.saleEarnest))}</span>
                  </div>
                </td>
                <td>
                  <div className="flex-center-center no-wrap">
                    <span>
                      {numberToStringWithComma(
                        Number(detail.saleDeposit) - Number(detail.saleEarnest),
                      )}
                    </span>{" "}
                    <span className="mx10"> / 계약 후</span>
                    <div className=" text-right">
                      <span>{detail.balanceFullPaymentDate}</span>
                    </div>
                    <span className="mx10">일 이내</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      {/* 이용료 */}
      <section className="contents-container__grid contents-container__1200">
        <div className="contents-container__grid-index">
          <p>이용료</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div>기준일</div>
                </th>
                <th>
                  <div>금액(부가세 별도 / 원)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="ml10 mr16 text-center">1개월</span>
                  </div>
                </td>
                <td>
                  <div className="minmax220 flex-center-center no-wrap">
                    <span>
                      {detail.rentalCostList?.length &&
                        numberToStringWithComma(Number(detail.productionPrice))}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      {/* 이용료 할인 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>이용료 할인</p>
        </div>
        <div className="contents-container__grid-contents">
          <table className="inner-table">
            <thead>
              <tr>
                <th>
                  <div>기준일</div>
                </th>
                <th>
                  <div>금액(부가세 별도 / 원)</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="minmax180 flex-center-center no-wrap">
                    <span className="ml10 mr16 text-center">1개월</span>
                  </div>
                </td>
                <td>
                  <div className="minmax220 flex-center-center no-wrap">
                    <span>
                      {detail.saleRentalCostList?.length &&
                        numberToStringWithComma(Number(detail.saleRentalCostList[0].value1))}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      {/* 이용료 안내 팝업 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>이용료 안내 팝업</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <span>{detail.isInformPopup ? "설정" : "설정 안 함"}</span>
          </div>
          <div className="minmax400">
            <p className="pre-formatted">{detail.informPopupDesc}</p>
          </div>
        </div>
      </section>

      {/*  */}
      {/* 관리비 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>관리비</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            {detail.maintenanceFee === 0 ? <span>부과하지 않음</span> : <span>부과</span>}
            <div className="minmax100 flex-center-center ml10">
              <span>{numberToStringWithComma(Number(detail.maintenanceFee))}</span>
              {/* <span className="mx5">원</span> */}
              <span className="no-wrap">/ 월</span>
            </div>
          </div>
          <table className="inner-table mt16">
            {detail.maintenanceFee !== 0 && (
              <>
                <thead>
                  <tr>
                    <th>
                      <div>항목</div>
                    </th>
                    <th>
                      <div>구분</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* 리스트 피그마 및 기획서 참고 */}

                  {maintainArray
                    ?.sort((a: any, b: any) => a.orderNums - b.orderNums)
                    .map((item, idx: number) => {
                      const names = () => {
                        if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_1") {
                          return "일반 관리";
                        }
                        if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_2") {
                          return "공용부 청소";
                        }
                        if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_3") {
                          return "공동 전기";
                        }
                        if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_4") {
                          return "공동 수도";
                        }
                        if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_5") {
                          return "공동 가스";
                        }
                        if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_6") {
                          return "청소";
                        }
                        if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_7") {
                          return "전기";
                        }
                        if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_8") {
                          return "수도";
                        }
                        if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_9") {
                          return "가스";
                        }
                      };

                      const labelChange = () => {
                        if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_7") {
                          return "실비 청구";
                        } else if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_8") {
                          return "실비 청구";
                        } else if (item.metaItem === "PRODUCT_MAINTENANCE_FEE_9") {
                          return "실비 청구";
                        } else {
                          return "해당사항 없음";
                        }
                      };

                      return (
                        <tr key={item.id}>
                          <td>
                            <div className="minmax180 flex-center-center no-wrap">
                              <span className="ml10 mr16 text-center">{item && names()}</span>
                            </div>
                          </td>
                          <td>
                            <div className="minmax260 flex-center-center no-wrap">
                              {item.value1 === "true" ? (
                                <span>관리비 포함</span>
                              ) : (
                                <span>{labelChange()}</span>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  {/* 리스트 피그마 및 기획서 참고 */}
                </tbody>
              </>
            )}
          </table>
        </div>
      </section>

      {/* 사용인원 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>사용인원</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <div className="text-right">
              <span>{detail.useNums}</span>
            </div>
            <span className="ml10 mr16">명</span>

            <span>{detail.isDeskChairIncluded ? "책상/의자 포함" : "책상/의자 없음"}</span>
          </div>
        </div>
      </section>
      {/* 계정 최대 인원 */}
      <section className="contents-container__grid">
        <div className="contents-container__grid-index">
          <p>계정 최대 인원</p>
        </div>
        <div className="contents-container__grid-contents">
          <div className="flex-row flex-center-start">
            <div className="text-right">
              <span>{detail.maxNums}</span>
            </div>
            <span className="ml10 mr16">명</span>
          </div>
        </div>
      </section>
    </article>
  );
};
export default FullCourtDetail;
