import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { CommonFacilityType } from "src/api/building/building-types";
import { useApiOperation } from "src/api/hooks";
import { getPartner } from "src/api/partner";
import { Order, PageMeta, Sort } from "src/api/public-types";
import {
  getReservationListAsync,
  getReservationUsageListAsync,
} from "src/api/reservation/reservation-api";
import {
  ReservationListModel,
  ReservationListParams,
  ReservationUsageParams,
} from "src/api/reservation/reservation-types";
import { BaseDatePicker, BaseInput, BaseSelect, BaseTable } from "src/components";
import BaseMultiSelect from "src/components/BaseMultiSelect";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { useLoadingBarContext } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import { YmdFormat, downloadExcel } from "src/utils";
import {
  ExternalServiceType,
  ReservationExternalTypes,
  ReservationState,
  getStatusText,
  reservationHeaders,
  reservationStatuses,
  reservationUsageHeaders,
} from "../reservation-types";
import { reservationColumns } from "./columns";

// url query parameter 타입
type QueryParams = {
  page?: number;
  size?: number;
  start?: string;
  end?: string;
  searchType?: string;
  keyword?: string; // 인풋 value로 사용
  search001?: string;
  statusCode?: string;
  inboundChannel?: ReservationExternalTypes;
  facilityType?: CommonFacilityType;
  reservationStatus?: string;
  sort?: Sort;
  parentId?: string;
  partnerId?: string;
  partnerName?: string;
  [key: string]:
    | string
    | number
    | ReservationExternalTypes
    | CommonFacilityType
    | Sort
    | undefined
    | unknown; // 문자열 인덱스 시그니처 추가
};

// 검색 대상
const searchTypes = [
  { value: "ALL", label: "전체" },
  { value: "containsFacilityName", label: "공간 명" },
  { value: "containsBuildingName", label: "건물 명" },
  { value: "containsOrganizerMemberNo", label: "회원번호" },
  { value: "id", label: "ID" },
  { value: "parentId", label: "그룹 ID" },
  { value: "containsContractApplyNumber", label: "신청번호" },
];

// 공간 타입
const facilityType = [
  { value: "", label: "전체" },
  { value: "MEETING_ROOM", label: "회의실" },
  { value: "DESK", label: "좌석" },
  { value: "REFRESH_ROOM", label: "편의시설" },
];

// 상태
const statuses = [
  { value: "", label: "전체" },
  { value: ReservationState.ACCEPTED, label: "접수" },
  { value: ReservationState.ACKNOWLEDGED, label: "점유" },
  { value: ReservationState.INCOMPLETE, label: "불완전" },
  { value: ReservationState.CANCELLED, label: "취소" },
];

// 예약경로
const inboundChannel = [
  { value: "", label: "전체" },
  { value: ExternalServiceType.TAAP, label: "Taap" },
  { value: ExternalServiceType.GC, label: "Google Calendar" },
  { value: ExternalServiceType.OC, label: "Outlook Calendar" },
  { value: ExternalServiceType.TAAP_SPACE, label: "TaapSPACE" },
  { value: ExternalServiceType.CTRL_ROOM, label: "Ctrl.room" },
];
/**
 * 공용공간 예약 > 목록 화면
 */
const ReservationList = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
      decoder: (value) => value,
    });

    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC", nullHandling: "NULLS_LAST" }],
      };
    }
    if (_queryParams?.sort) {
      _queryParams.sort = {
        orders: _queryParams.sort.orders?.map((order) => ({
          ...order,
          nullHandling: "NULLS_LAST",
        })),
      };
    }
    if (!_queryParams?.keyword) {
      _queryParams.keyword = "";
    }
    if (!_queryParams?.start) {
      _queryParams.start = moment().subtract(30, "days").format("YYYY-MM-DD") + "T00:00:00+09:00";
    }
    if (!_queryParams?.end) {
      _queryParams.end = moment().format("YYYY-MM-DD") + "T23:59:59.999+09:00";
    }

    const queryParamsToDecode = [
      "start",
      "end",
      "keyword",
      "search001",
      "id",
      "parentId",
      "containsFacilityName",
      "containsBuildingName",
      "containsOrganizerMemberNo",
      "containsContractApplyNumber",
    ];

    for (const key of queryParamsToDecode) {
      if (_queryParams?.[key]) {
        _queryParams[key] = decodeURIComponent(_queryParams[key] as string);
      }
    }

    return _queryParams;
  }, [location]);

  const [reservationList, setReservationList] = useState<Array<ReservationListModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  // 공용공간 엑셀 목록
  const [csvData, setCsvData] = useState<ReservationListModel[]>([]);

  // 받아온 query parameter 로 해당 컴포넌트에서 변경가능하기 위한 state 선언
  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]); // 선택된 파트너

  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState<boolean>(false);

  const { executeAsync: getReservationList } = useApiOperation(getReservationListAsync);
  const { executeAsync: getReservationUsageList } = useApiOperation(getReservationUsageListAsync); // 예약 소진시간 목록 조회
  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner); // 파트너 조회

  const searchTypeKey = [
    //검색조건
    "id",
    "parentId",
    "containsFacilityName",
    "containsBuildingName",
    "containsOrganizerMemberNo",
    "containsContractApplyNumber",
  ];

  const includeSearchType = () => {
    return searchTypeKey.find((item) => item in params) || "";
  };

  const removeOtherSearchType = (searchType: string) => {
    const removedOtherSearchTypeArray = searchTypeKey.filter((item) => item !== searchType);
    let newParams: any = { ...params };
    for (const key in params) {
      if (removedOtherSearchTypeArray.includes(key)) {
        delete newParams[key];
        delete newParams.ALL;
      }
    }
    return newParams;
  };
  // 공용공간 예약 리스트 api
  const getReservationListApi = useCallback(
    async (reservationParams: ReservationListParams & { reservationStatus?: string }) => {
      setLoadingBar(true);
      const newReservationParams = { ...reservationParams };

      if (newReservationParams.keyword === "") {
        delete newReservationParams.keyword;
      }
      if (newReservationParams.search001 !== "" && newReservationParams.search001 !== undefined) {
        newReservationParams.search001 = decodeURIComponent(newReservationParams.search001 || "");
      }
      if (newReservationParams.reservationStatus) {
        if (newReservationParams.reservationStatus === "RESERVATION_FAILED") {
          // 예약실패
          newReservationParams.statusCode = ReservationState.INCOMPLETE;
        } else if (newReservationParams.reservationStatus === "WAITING_FOR_USE") {
          // 이용대기
          newReservationParams.statusCode = ReservationState.ACKNOWLEDGED;
          newReservationParams.greaterThanStart = moment().format(YmdFormat.WITH_TIME_ZONE);
        } else if (newReservationParams.reservationStatus === "IN_USE") {
          // 이용중
          newReservationParams.statusCode = ReservationState.ACKNOWLEDGED;
          newReservationParams.lessThanEqualsStart = moment().format(YmdFormat.WITH_TIME_ZONE);
          newReservationParams.greaterThanEnd = moment().format(YmdFormat.WITH_TIME_ZONE);
        } else if (newReservationParams.reservationStatus === "USE_COMPLETED") {
          // 이용완료
          newReservationParams.statusCode = ReservationState.ACKNOWLEDGED;
          newReservationParams.lessThanEqualsEnd = moment().format(YmdFormat.WITH_TIME_ZONE);
        } else if (newReservationParams.reservationStatus === "USE_CANCELLED") {
          // 이용완료
          newReservationParams.statusCode = ReservationState.CANCELLED;
        }
        delete newReservationParams.reservationStatus;
      }

      const response = await getReservationList(newReservationParams);
      if (params.partnerId) {
        // 파트너아이디 있음
        await fetchPartner(String(params.partnerId));
      }
      if (response.status >= 200 && response.status <= 299) {
        const result = response.data.data.content;
        setReservationList(result);
        setPageMeta(response.data.meta.pageMeta);
        setLoadingBar(false);
      } else {
        setLoadingBar(false);
      }
    },
    [getReservationList, setLoadingBar],
  );

  // 파트너 조회
  const fetchPartner = async (id: string) => {
    const response = await getPartnerAsync({ id });
    if (response.status >= 200 && response.status <= 299) {
      const partner = response.data.data.partner;
      setSelectedPartners([partner]);
    }
  };
  const onAddSelectPartners = (partners: Partner[]) => {
    setSelectedPartners(partners);
    setIsPartnerModalOpen(false);
    setParams({
      ...params,
      partnerId: String(partners[0].id),
      partnerName: decodeURIComponent(String(partners[0].name)),
    });
  };

  useEffect(() => {
    // if (!startDate || !endDate) return;
    setParams({ ...queryParams }); // input 초기화
    // setDateRange([moment(queryParams.startDate).toDate(), moment(queryParams.endDate).toDate()]);
    const _queryParams = { ...queryParams };

    const reservationListParams: ReservationListParams = {
      ..._queryParams,
    };

    getReservationListApi(reservationListParams);
  }, [getReservationListApi, queryParams]);

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams & { ALL?: string }, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      let newQueryParams = { ...data, ...(passParams || {}) };
      if (includeSearchType() !== "") {
        delete newQueryParams.search001;
      } else {
        newQueryParams = { ...newQueryParams, search001: newQueryParams.keyword };
      }
      delete newQueryParams.ALL;
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  /** 공용공간 예약목록 엑셀 다운로드 */
  const onDownloadReservations = async (passParams: QueryParams) => {
    if (!reservationList) return;
    const newParams = { ...passParams };
    let excelData: any = [];
    const defaultSize = 1000;

    const reservationListParams: ReservationListParams = {
      ...params,
      ...{
        statusCode: newParams.statusCode,
      },
      search001: decodeURIComponent(newParams?.search001 || ""),
      page: 0,
      size: defaultSize,
    };

    const totalPages = Math.ceil(pageMeta?.totalElements! / defaultSize);
    for (let i = 0; i < totalPages; i++) {
      const { data } = await getReservationList(reservationListParams);
      reservationListParams.page = i + 1;
      excelData = [...excelData, ...data.data.content];
    }

    if (excelData.length > 0) {
      // 목록이 나오면 다운받을 엑셀 형태를 수정
      const newData = excelData?.map((item: any) => {
        item = {
          id: item.id,
          reservationGroupId: item.parentId,
          productId: item.facility.building?.id,
          contract: item.contract?.applyNumber,
          inboundChannel: item.inboundChannel,
          buildingName: item.facility?.building?.name,
          buildingCommonFacilityFloorNum: Math.abs(item.facility.floor),
          buildingCommonFacilityIsGround: item.facility.floor > 0 ? "지상" : "지하",
          buildingCommonFacilityId: item.facility.id,
          buildingCommonFacilityLocationCode: item.facility.locationCode,
          facility:
            item.facility.type === "DESK"
              ? "좌석"
              : item.facility.type === "MEETING_ROOM"
              ? "회의실"
              : "편의시설", //공간타입
          buildingCommonFacilityName: item.facility.name,
          start: moment(item.start).format(YmdFormat.YYYY_MM_DD_HH_MM), //예약기간 (시작일시)
          end: moment(item.end).format(YmdFormat.YYYY_MM_DD_HH_MM), //예약기간 (종료일시)
          memberNo: item.organizer.memberNo, // 회원번호
          memberEmail: item.organizer.userEmail,
          summary: item.summary,
          createdDate: moment(item.createdDate).format(YmdFormat.YYYY_MM_DD_HH_MM), // 생성일자
          status: getStatusText(item.status.code), //상태
        };

        return item;
      });
      try {
        const fileName =
          params.start && params.end
            ? `ctrl.room_공용공간예약_${moment(params.start).format("YYYYMMDD")}~${moment(
                params.end,
              ).format("YYYYMMDD")}_${moment().format("YYYYMMDDHHmm")}`
            : `ctrl.room_공용공간예약_전체_${moment().format("YYYYMMDDHHmm")}`;
        await downloadExcel({
          data: newData,
          fileName,
          header: reservationHeaders.map((header) => header.label),
        });
        // setAlertModal({ isOpen: true, title: "엑셀 다운로드가 완료되었습니다." });
      } catch (error) {
        console.log(error);
        // setAlertModal({ isOpen: true, title: "엑셀 다운로드에 실패하였습니다.", message: String(error) });
      }
    }
  };
  const onDownloadReservationUsages = async (passParams: QueryParams) => {
    if (!reservationList) return;
    const { sort, page, size, ...rest } = passParams;
    let excelData: any = [];
    const defaultSize = 1000;
    const reservationUsageParams: ReservationUsageParams = {
      reservation: {
        ...rest,
      },
      sort: {
        orders: [
          { property: "reservationId", direction: "DESC" },
          { property: "order", direction: "ASC" },
        ],
      },
      page: 0,
      size: defaultSize,
    };

    const totalPages = Math.ceil(pageMeta?.totalElements! / defaultSize);

    for (let i = 0; i < totalPages; i++) {
      const { data } = await getReservationUsageList(reservationUsageParams);
      reservationUsageParams.page = i + 1;
      excelData = [...excelData, ...data.data.content];
    }

    // 목록이 나오면 다운받을 엑셀 형태를 수정
    if (excelData.length > 0) {
      // 한글 데이터로 변경
      const newData = excelData?.map((item: any) => {
        if (item.endDate && item.startDate) {
        }
        //reservationUsageHeaders
        item = {
          reservationId: item.reservationId, // id
          contractApplyNumber: item.contract.applyNumber, // 신청/계약 번호
          facilityBuildingName: item.facility.building.name, //건물명
          facilityType: item.facility.type, //공간타입
          facilityId: item.facility.id, // 공간id
          facilityName: item.facility.name, //공간명
          start: moment(item.start).format(YmdFormat.YYYY_MM_DD_HH_MM), //예약기간 (시작일시),
          end: moment(item.end).format(YmdFormat.YYYY_MM_DD_HH_MM), //예약기간 (시작일시),
          organizerMemberNo: item.organizer.memberNo, // 예약자 (회원번호)
          organizerPhoneNumber: item.organizer.phoneNumber, //예약자 (휴대폰번호)
          organizerUserEmail: item.organizer.userEmail, //예약자 (이메일)
        };

        return item;
      });

      try {
        const fileName =
          params.start && params.end
            ? `ctrl.room_공용공간예약_소진시간_${moment(params.start).format("YYYYMMDD")}~${moment(
                params.end,
              ).format("YYYYMMDD")}_${moment().format("YYYYMMDDHHmm")}`
            : `ctrl.room_공용공간예약_소진시간_전체_${moment().format("YYYYMMDDHHmm")}`;
        await downloadExcel({
          data: newData,
          fileName,
          header: reservationUsageHeaders.map((header: any) => header.label),
        });
        // setAlertModal({ isOpen: true, title: "엑셀 다운로드가 완료되었습니다." });
      } catch (error) {
        // setAlertModal({ isOpen: true, title: "엑셀 다운로드에 실패하였습니다.", message: String(error) });
      }
    }
  };
  return (
    <div className="page-product-list">
      {/* <ContentsTitle
        title="공용공간 예약"
        breadCrumbs={[{ value: "reservation", label: "공용공간 예약" }]}
      /> */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{pageMeta?.totalElements || 0}</span>
            </p> */}
          <div className="minmax160 mr8">
            <BaseInput
              readonly
              placeholder="파트너"
              value={selectedPartners[0]?.name || params.partnerName}
              onSearchClick={() => setIsPartnerModalOpen(true)}
              onClearClick={() => {
                setSelectedPartners([]);
                const { partnerId, partnerName, ...rest } = params;
                setParams(rest);
              }}
            />
          </div>

          <div className="minmax120 mr8">
            <BaseSelect
              placeholder="공간 타입"
              value={params.facilityType}
              className="pre-wrap"
              stateOptions={facilityType}
              setStateValue={(facilityType: CommonFacilityType) =>
                setParams({ ...params, facilityType })
              }
            />
          </div>

          <div className="minmax185 mr8">
            <BaseSelect
              placeholder="예약경로"
              value={params.inboundChannel}
              stateOptions={inboundChannel}
              setStateValue={(inboundChannel: ReservationExternalTypes) =>
                setParams({ ...params, inboundChannel })
              }
            />
          </div>
          <div className="minmax120 mr8">
            {/* <BaseMultiSelect
              placeholder="점유 상태"
              stateOptions={statuses}
              value={returnStatusCodes || []}
              setStateValue={(options: Array<{ value: string; label: string }>) => {
                const statusCode = options
                  .map((obj: { value: string; label: string }) => obj.value)
                  .join(",");
                setParams({ ...params, statusCode });
                navigateWithQueryParams({ page: 0, ...params, statusCode });
              }}
            /> */}
            <BaseSelect
              placeholder="점유 상태"
              value={params.statusCode}
              stateOptions={statuses}
              setStateValue={(value: string) => {
                setParams({ ...params, statusCode: value });
              }}
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              placeholder="예약 상태"
              value={params.reservationStatus}
              stateOptions={reservationStatuses}
              setStateValue={(value: string) => {
                setParams({ ...params, reservationStatus: value });
                // navigateWithQueryParams({ ...params, reservationStatus: value, page: 0 });
              }}
            />
          </div>
          <div className="minmax120 mr8">
            <BaseDatePicker
              setDate={(date: string) => {
                if (date !== "" && date !== null && date !== "Invalid date") {
                  let start = moment(date).format("YYYY-MM-DD") + "T00:00:00+09:00";

                  setParams({ ...params, start: String(start) });
                }
              }}
              selectedDate={moment(params.start).toDate() || null}
              placeholder="시작일"
            />
          </div>
          <div className="mr8">
            <span>~</span>
          </div>
          <div className="minmax120 mr8">
            <BaseDatePicker
              setDate={(date: string) => {
                if (date !== "" && date !== null && date !== "Invalid date") {
                  let end = moment(date).format("YYYY-MM-DD") + "T23:59:59.999+09:00";

                  setParams({ ...params, end: String(end) });
                }
              }}
              selectedDate={moment(params.end).toDate() || null}
              placeholder="종료일"
              minDate={moment(params.start).toDate()}
            />
          </div>
          <div className="minmax120 mr8">
            <BaseSelect
              placeholder="검색 대상"
              value={includeSearchType()}
              stateOptions={searchTypes}
              setStateValue={(searchType: string) => {
                if (searchType !== "") {
                  const type = { [searchType]: params?.keyword };
                  const newParams = removeOtherSearchType(searchType);
                  setParams({ ...newParams, ...type });
                } else {
                  setParams({ ...params, keyword: params?.keyword });
                }
              }}
            />
          </div>
          <BaseInput
            type="text"
            value={decodeURIComponent(String(params?.keyword) || "")}
            className="mr8"
            placeholder="검색어를 입력해주세요"
            onChange={(keyword: string) => {
              if (includeSearchType() !== "" && includeSearchType() !== "ALL") {
                const type = { [includeSearchType()]: keyword, keyword };
                setParams({ ...params, ...type });
              } else {
                setParams({ ...params, keyword });
              }
            }}
            onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
            onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
          />
        </div>
        <div className="right-area">
          <div className="flex-center">
            <button
              className="base-download-btn reservation mr8"
              onClick={() => {
                onDownloadReservations(params);
              }}
            >
              예약내역
            </button>
            <button
              className="base-download-btn reservation"
              onClick={() => {
                onDownloadReservationUsages(params);
              }}
            >
              소진시간
            </button>
            {/* <CsvLinkComponent csvData={csvData} setCsvData={setCsvData} /> */}
          </div>
        </div>
      </div>
      {isPartnerModalOpen && (
        <PartnerSelectModal
          onCanceled={() => {
            setIsPartnerModalOpen(false);
          }}
          onAdded={onAddSelectPartners}
          defaultValues={selectedPartners || []}
        />
      )}

      <BaseTable
        data={reservationList}
        columns={reservationColumns}
        pageIndex={Number(params?.page || 0)}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page }, "pagination");
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={[
          "memberNo",
          "memberEmail",
          "end",
          "inboundChannel",
          "facility",
          "facility.floor",
          "organizer.memberNo",
          "organizer.userEmail",
          "organizer.phoneNumber",
          "partnerId",
        ]}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } }, "search");
          }
        }}
      />
    </div>
  );
};
export default ReservationList;
