import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getAccessDeviceAsync,
  getExternalServersAsync,
  postAccessDeviceAsync,
  putAccessDeviceAsync,
} from "src/api/access/ac2-api";
import { AccessDeviceFormParams, AccessDeviceModel } from "src/api/access/ac2-types";
import { useApiOperation } from "src/api/hooks";
import { Modal, Select } from "src/api/public-types";
import { BaseButton, BaseInput, BaseModal, BaseSelect, ContentsIdSection } from "src/components";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { PagePath } from "src/pages/product/details";
import PartnersTable from "./PartnersTable";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";
import { Partner } from "src/types/partner";
import { getPartnerList } from "src/api/partner";

/*
  AccessDeviceForm 등록 or 수정
*/

const BasicInfoForm = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openToast } = useToastContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  type SubmitData = AccessDeviceFormParams & {
    externalServerId: string;
  };

  const id: number | undefined = queryParams?.id ? Number(queryParams.id) : undefined;

  const [accessDevice, setAccessDevice] = useState<AccessDeviceModel | undefined>();
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);
  const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
  const { executeAsync: getAccessDevice } = useApiOperation(getAccessDeviceAsync);
  const { executeAsync: postAccessDevice } = useApiOperation(postAccessDeviceAsync, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getPartners } = useApiOperation(getPartnerList);
  const { executeAsync: getExternalServerList } = useApiOperation(getExternalServersAsync);
  const { executeAsync: putAccessDevice } = useApiOperation(putAccessDeviceAsync);
  const { openErrorModal } = useErrorModalContext();
  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });
  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 작성취소 모달
  const [uncreateModal, setUncreateModal] = useState<Modal>({
    isOpen: false,
  });

  // 저장할 form data
  const [submitData, setSubmitData] = useState<AccessDeviceFormParams>();
  const [externalOptions, setExternalOptions] = useState<Select[]>([]);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setFocus,
    watch,
    formState: { errors },
  } = useForm<SubmitData>({
    defaultValues: {
      id: "",
      externalServerId: "",
      externalDeviceId: "",
      deviceName: "",
    },
  });

  useEffect(() => {
    // 외부출입시스템 목록 조회
    const fetchExternal = async () => {
      const { data: externalServerList } = await getExternalServerList();
      const externalServerData = externalServerList?.data?.content;

      let searchAccessSystemTypes = [];
      const newSearchAccessSystemTypes = externalServerData.map((server) => {
        return { value: String(server.id!), label: `${server.serverCode}: ${server.serverName!}` };
      });

      searchAccessSystemTypes = [...newSearchAccessSystemTypes];
      setExternalOptions(searchAccessSystemTypes);
    };
    fetchExternal();
    // id가 있을경우 accessDevice 상세 호출
    if (id) {
      async function fetchDevice(id: number) {
        setLoadingBar(true);
        const { data } = await getAccessDevice({ id });
        const accessDeviceData = data?.data?.device;

        setAccessDevice(accessDeviceData);
        setLoadingBar(false);
      }
      fetchDevice(id);
    }
  }, [setLoadingBar, getAccessDevice]);

  // 수정시 api 에서 받아온 device 정보로 setValue 처리
  useEffect(() => {
    if (accessDevice) {
      Object.entries(accessDevice).forEach(([name, value]: any) => {
        setValue(name, value);
      });
      setValue("externalServerId", String(accessDevice?.externalServer?.id));
    }
  }, [accessDevice, setValue]);

  useEffect(() => {
    const fetchApi = async (partnersNumber: string) => {
      const data = await getPartners({ id: partnersNumber });
      const partnersResponseData = data?.data?.data?.content;
      setSelectedPartners(partnersResponseData);
    };
    if (accessDevice?.partnerIds && accessDevice?.partnerIds.length > 0) {
      fetchApi(accessDevice?.partnerIds.join());
    }
  }, [getPartners, accessDevice?.partnerIds]);

  // react hook form 에서 사용하는 validation rules, error message 정의
  useEffect(() => {
    const requiredMessage = "필수입력항목입니다";
    register("externalServerId", {
      required: requiredMessage,
    });
    register("externalDeviceId", {
      required: requiredMessage,
    });
    register("deviceName", {
      required: requiredMessage,
    });
  }, [register]);

  // validation 통과 후 submit 될때 실행
  const onSubmit = (data: SubmitData, e?: any) => {
    e.preventDefault();
    const addPartnersIds = selectedPartners.map((partner) => Number(partner.id)!);
    const newAddPartnerIds = addPartnersIds.filter(
      (ids) => !accessDevice?.partnerIds?.includes(String(ids)),
    );
    const deletePartnersIds = accessDevice?.partnerIds?.filter(
      (partner) => !addPartnersIds.includes(Number(partner)),
    );
    if (id) {
      const newSubmitData: AccessDeviceFormParams = {
        id: data.id,
        externalServer: {
          id: Number(data.externalServerId),
        },
        externalDeviceId: data.externalDeviceId,
        deviceName: data.deviceName,
        partnerAddIds: newAddPartnerIds,
        partnerDeleteIds: deletePartnersIds?.map((id) => Number(id)),
      };

      console.log(newSubmitData);
      setSubmitData(newSubmitData);
    } else {
      const newSubmitData: AccessDeviceFormParams = {
        externalServer: {
          id: Number(data.externalServerId),
        },
        externalDeviceId: data.externalDeviceId,
        deviceName: data.deviceName,
        partnerAddIds: newAddPartnerIds,
      };
      console.log(newSubmitData);
      setSubmitData(newSubmitData);
    }
    setConfirmModal({ message: "저장하시겠습니까?", isOpen: true });
  };

  // Modal 확인 버튼 클릭 (submit)
  const clickModalConfirm = async () => {
    setConfirmModal({ isOpen: false });
    setLoadingBar(true);

    if (!submitData) {
      throw Error("저장할 data 없음");
    }

    let result;
    if (id) {
      // 수정
      const updateResult = await putAccessDevice(submitData);
      result = updateResult;
    } else {
      // 등록
      const createResult = await postAccessDevice(submitData);
      result = createResult;
    }
    setLoadingBar(false);

    if (result?.data?.data?.device) {
      setAlertModal({
        isOpen: true,
        message: "저장 되었습니다.",
        payload: { id: result?.data?.data?.device.id },
        type: "addDevice",
      });
    } else if (result?.data?.meta?.errorCode === "eAC105") {
      setAlertModal({
        isOpen: true,
        message: "동일 출입 시스템 내에 이미 등록된 장치id가 있습니다.",
        type: "eAC105",
      });
    } else {
      let errorCode = "";
      let errorMessage = "";
      let errorData;
      if (result?.data?.meta?.errorCode) {
        errorCode = result?.data?.meta?.errorCode;
      }
      if (result?.data?.meta?.errorMessage) {
        errorMessage = result?.data?.meta?.errorMessage;
      }
      if (result?.data?.meta?.errorData) {
        errorData = result?.data?.meta?.errorData;
      }
      openErrorModal(errorMessage, errorCode, errorData);
    }
  };

  // 파트너 선택
  const onAddSelectPartners = (partners: Partner[]) => {
    setSelectedPartners(partners);
    setIsPartnerModalOpen(false);
  };
  // 파트너 제거
  const handleDeletePartner = (id: string) => {
    const filteredPartners = selectedPartners.filter((partner) => partner.id !== id);
    setSelectedPartners(filteredPartners);
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    e.preventDefault();
    return false;
  };

  // 데이터를 하나라도 입력했는지?
  const isChangedData = () => {
    const defaultValues = {
      id: "",
      externalServerId: "",
      externalDeviceId: "",
      deviceName: "",
    };

    const stringifyDefaultValues = JSON.stringify(defaultValues);
    const stringifyData = JSON.stringify(getValues());

    return stringifyDefaultValues === stringifyData ? false : true;
  };
  useTitleOperation(accessDevice?.deviceName);

  return (
    <div>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="contents-container__wrap-contents">
              <ContentsIdSection title="장치 등록" id={id} />
              <article className="contents-container__1200">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">출입장치 ID</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax240">
                      <Controller
                        control={control}
                        name="externalDeviceId"
                        render={({
                          field: { onChange, value, name, ref },
                          fieldState: { error },
                        }) => (
                          <BaseInput
                            inputRef={ref}
                            placeholder=""
                            className="mr8"
                            type="text"
                            onChange={onChange}
                            value={value}
                            name={name}
                            errorText={error?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">장치명</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax240">
                      <Controller
                        control={control}
                        name="deviceName"
                        render={({
                          field: { onChange, value, name, ref },
                          fieldState: { error },
                        }) => (
                          <BaseInput
                            inputRef={ref}
                            placeholder=""
                            className="mr8"
                            type="text"
                            onChange={onChange}
                            value={value}
                            name={name}
                            errorText={error?.message}
                          />
                        )}
                      />
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p className="required">출입 시스템</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <Controller
                      control={control}
                      name="externalServerId"
                      render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                          <div className="minmax240">
                            <BaseSelect
                              stateOptions={externalOptions}
                              value={value}
                              setStateValue={onChange}
                            />
                            {error && <p className="validation-text">{error.message}</p>}
                          </div>
                        );
                      }}
                    />
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>파트너</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <BaseButton title={"파트너 추가"} onClick={() => setIsPartnerModalOpen(true)} />
                    <PartnersTable
                      type="FORM"
                      partners={selectedPartners || []}
                      handleDeletePartner={handleDeletePartner}
                    />
                  </div>
                </section>
              </article>
            </div>

            {/* 버튼영역 */}
            <div className="contents-container__btn-wrap">
              <div className="left-area">
                <BaseButton
                  title="목록으로"
                  type="button"
                  className="color-white size-large"
                  onClick={() => {
                    if (!id && isChangedData()) {
                      setUncreateModal({
                        message: "작성중인 내용이 있습니다. 계속 할까요?",
                        isOpen: true,
                      });
                    } else {
                      if (id) {
                        // 수정화면에서는 목록으로 바로이동
                        navigate(PagePath.accessDevice.list);
                      } else {
                        // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                        navigate(-1);
                      }
                    }
                  }}
                />
              </div>
              <div className="right-area">
                <BaseButton type="submit" title="저장" className=" size-large" />
              </div>
            </div>
          </form>
          {/* 파트너 선택 모달 */}
          {isPartnerModalOpen && (
            <PartnerSelectModal
              multiSelect
              onCanceled={() => {
                setIsPartnerModalOpen(false);
              }}
              onAdded={onAddSelectPartners}
              defaultValues={selectedPartners || []}
            />
          )}

          {/* 취소, 확인 버튼이 있는 confirm 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => clickModalConfirm()}
          >
            <p>{confirmModal.message}</p>
          </BaseModal>

          {/* 저장완료시 알럿 */}
          <BaseModal
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => {
              if (alertModal.type === "addDevice") {
                const detailPath = `${PagePath.accessDevice.detail.replace(
                  ":id",
                  `${alertModal.payload.id}`,
                )}`;
                setAlertModal({ isOpen: false });
                openToast(`정상적으로 ${id ? "수정" : "등록"} 되었습니다.`);
                navigate(detailPath);
              } else if (alertModal.type === "eAC105") {
                setAlertModal({ isOpen: false });
              }
            }}
          >
            <p>{alertModal.message}</p>
          </BaseModal>

          {/* 저장된것이 있을때 확인하는 모달 */}
          <BaseModal
            isOpen={uncreateModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClick={() => {
              if (id) {
                // 수정화면에서는 목록으로 바로이동
                navigate(PagePath.accessDevice.list);
              } else {
                // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                navigate(-1);
              }
            }}
            onClose={() => setUncreateModal({ isOpen: false })}
          >
            <p>{uncreateModal.message}</p>
          </BaseModal>
        </div>
      </div>
    </div>
  );
};

export default BasicInfoForm;
