/* eslint-disable array-callback-return */
import _ from "lodash";
import moment from "moment";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  addNUpdateOperationData,
  delCalendarInfo,
  getCalendarInfo,
  getContractFacilityList,
  getContractOperationInfo,
  getProductFacilityList,
  saveCalendarInfo,
  saveContractOperation,
} from "src/api/contract/contract-api";
import {
  CalendarInfo,
  CalendarServiceType,
  ContractStep,
  OutlookResource,
} from "src/api/contract/contract-types";
import { errorMessageHandler } from "src/api/error/contract-response-error";
import { useApiOperation } from "src/api/hooks";
import { getProductDetailAsync } from "src/api/product/product-api";
import { contract_bill_user_errors } from "src/api/public-types";
import {
  BaseButton,
  BaseInput,
  BaseModal,
  BaseRadio,
  BaseToggle,
  BaseTooltip,
} from "src/components";
import { ContentsIdSection } from "src/components/ContentsIdSection";
import pagePath from "src/pagePath.json";
import { useLoadingBarContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { showHourMinute, validateEmail, YmdFormat } from "src/utils";
import SearchContractFacilityPopup from "./modal/SearchContractFacilityPopup";

enum CalendarInfoStatus {
  READY,
  EDIT,
  DELETE,
}

const ContractFormManage = ({ basicInfo, application, spaceProductType }: any) => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openErrorModal } = useErrorModalContext();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [deletedId, setDeletedId] = useState(0);
  const [calendarInfoStatus, setCalendarInfoStatus] = useState<CalendarInfoStatus>(
    CalendarInfoStatus.READY,
  );

  const [prFacilities, setPrFacilities] = useState([]);
  const navigate = useNavigate();
  const params = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location.search],
  );
  const [calendarData, setCalendarData] = useState<CalendarInfo>({
    contractId: String(params.id),
    contractManageId: 0,
    data: {
      service: CalendarServiceType.GOOGLE,
      dummyUserEmail: "",
      outlook: {
        registeredApp: {
          tenantId: "",
          clientId: "",
          clientCredentials: {
            clientSecret: {
              value: "",
            },
          },
        },
      },
      resources: [],
    },
  });
  const [calendarDataOri, setCalendarDataOri] = useState<CalendarInfo>();
  const [operation, setOperation] = useState<any>();
  const [operationOriginal, setOperationOriginal] = useState<any>();
  const [tempOperation, setTempOperation] = useState<any>();
  const [facilityType, setFacilityType] = useState("");
  const [error1, setError1] = useState("");
  const [error2, setError2] = useState("");
  const [error3, setError3] = useState("");
  const [error4, setError4] = useState("");
  const [error5, setError5] = useState("");
  const [error6, setError6] = useState("");

  const [useShowHide, setShowHide] = useState(false);
  const [useCalendar, setCalendar] = useState(false);

  const [useMeet, setUseMeet] = useState(false);
  const [useRefresh, setUseRefresh] = useState(false);
  const [useDesk, setUseDesk] = useState(false);
  const [useInvite, setUseInvite] = useState(false);
  // 캘린더 연동 정보 조회
  const { executeAsync: fetchCalendarInfo } = useApiOperation(getCalendarInfo);
  const { executeAsync: editCalendarInfo } = useApiOperation(saveCalendarInfo);
  const { executeAsync: removeCalendarInfo } = useApiOperation(delCalendarInfo);

  const { executeAsync: editOper } = useApiOperation(saveContractOperation, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getProductDetail } = useApiOperation(getProductDetailAsync);

  const { executeAsync: getOperation } = useApiOperation(getContractOperationInfo, {
    doNotErrorHandleModal: true,
  });
  // 운영-공용공간 조회
  const { executeAsync: getOperationFacility } = useApiOperation(getContractFacilityList, {
    doNotErrorHandleModal: true,
  });
  // 건물-상품 공용공간 조회
  const { executeAsync: getProductFacilitList } = useApiOperation(getProductFacilityList, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: insertOrUpdate } = useApiOperation(addNUpdateOperationData, {
    doNotErrorHandleModal: true,
  });

  const callOperation = useCallback(async () => {
    if (params?.id && basicInfo && basicInfo!.contractStep) {
      setLoadingBar(true);
      const prViewFacilities: any = [];
      const ctViewFacilities: any = [];

      const getFacilities: any = await getOperationFacility({ id: Number(params.id) });
      const res: any = await getOperation({ id: Number(params.id) });
      let callIds: string = "";
      // console.log("basicInfo", basicInfo);
      getFacilities.data.data.content.prProductFacility.forEach(
        (ctFacility: any, index: number) => {
          if (index > 0 && index < getFacilities.data.data.content.prProductFacility.length - 1) {
            callIds += String(ctFacility.id) + ",";
          } else if (index === getFacilities.data.data.content.prProductFacility.length - 1) {
            callIds += String(ctFacility.id);
          } else {
            callIds += String(ctFacility.id) + ",";
          }
        },
      );
      // console.log("callIds", callIds);
      const calResponse: any = await fetchCalendarInfo({ contractId: Number(params.id) });
      console.log("operation", res.data.data.content, "calResponse", calResponse);
      if (res.status >= 200 && res.status <= 299) {
        setUseMeet(res.data.data.content.productManage.isUseMeetingRoom);
        setUseRefresh(res.data.data.content.productManage.isUseRefreshRoom);
        setUseDesk(res.data.data.content.productManage.isUseDesk);

        const prFacilityDatasContent: any = await getProductFacilitList({
          searchType: "ID",
          keywordList: callIds,
          size: 9999,
        });
        const prFacilityDatas = prFacilityDatasContent.data.data.content;
        // console.log(res.data.data.content);

        // console.log("prFacilityDatas", prFacilityDatas);
        let response = getFacilities.data.data.content;

        if (prFacilityDatas) {
          response?.ctProductFacility.forEach((ctFacility: any) => {
            const finded = prFacilityDatas.find(
              (prFacility: any) => String(prFacility.id) === String(ctFacility.id),
            );
            // console.log("finded", finded);
            if (finded) {
              // console.log("prFacility.id, ctFacility.id", finded.id, ctFacility.id, finded.commonFacilityType);
              ctFacility.commonFacilityType = finded.commonFacilityType;
              ctFacility.buildingName = finded.buildingName;
              ctFacility.facilityName = finded.facilityName;
              ctFacility.isGround = finded.isGround;
              ctFacility.floorNum = finded.floorNum;
              ctFacility.buildingCommonFacilityDeskGroupName =
                finded.buildingCommonFacilityDeskGroupName
                  ? finded.buildingCommonFacilityDeskGroupName
                  : "";
              ctFacility.facilityMaxPeopleNums = finded.facilityMaxPeopleNums;

              ctViewFacilities.push(ctFacility);
            }
          });
          response?.prProductFacility.forEach((ctFacility: any) => {
            const finded = prFacilityDatas.find(
              (prFacility: any) => String(prFacility.id) === String(ctFacility.id),
            );
            // console.log("finded", finded);
            if (finded) {
              // console.log("prFacility.id, ctFacility.id", finded.id, ctFacility.id, finded.commonFacilityType);
              ctFacility.commonFacilityType = finded.commonFacilityType;
              ctFacility.buildingName = finded.buildingName;
              ctFacility.facilityName = finded.facilityName;
              ctFacility.isGround = finded.isGround;
              ctFacility.floorNum = finded.floorNum;
              ctFacility.buildingCommonFacilityDeskGroupName =
                finded.buildingCommonFacilityDeskGroupName
                  ? finded.buildingCommonFacilityDeskGroupName
                  : "";
              ctFacility.facilityMaxPeopleNums = finded.facilityMaxPeopleNums;
              prViewFacilities.push(ctFacility);
            }
          });
        }

        // console.log("response", response);
        res.data.data.content.ctProductFacility = response.ctProductFacility;
        res.data.data.content.prProductFacility = response.prProductFacility;

        // console.log("res", res);
        setOperationOriginal(_.cloneDeep(res.data.data.content));
        setOperation(res.data.data.content);

        //캘린더 정보가 있을 경우 데이터를 세팅한다.
        if (calResponse.data.data.config) {
          const getCalendar: CalendarInfo = calResponse.data.data.config;

          if (
            getCalendar.data &&
            getCalendar.data?.resources &&
            getCalendar.data?.resources?.length > 0
          ) {
            res.data.data.content.ctProductFacility.forEach((ctViewFacilities: any) => {
              const finded = getCalendar.data?.resources?.find(
                (fa: any) => Number(ctViewFacilities.id) === Number(fa.facilityId),
              );
              if (finded) {
                ctViewFacilities.resourceEmail = finded.email;
              }
            });
          }
          if (getCalendar.data) {
            setCalendar(true);
          } else {
            setCalendar(false);
          }

          setCalendarData({ ...calendarData, ...getCalendar });
          setCalendarDataOri(_.cloneDeep({ ...calendarData, ...getCalendar }));
        }
      } else {
        let message = errorMessageHandler(
          res.status,
          res.data.meta.errorCode,
          contract_bill_user_errors,
        );
        let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.staus;
        openErrorModal(message, code);
      }

      setLoadingBar(false);
    }
  }, [
    params.id,
    basicInfo,
    setLoadingBar,
    getOperationFacility,
    getOperation,
    getProductFacilitList,
    fetchCalendarInfo,
    openErrorModal,
  ]);

  //공간상품의 공용공간 사용여부 조회
  const checkProductDetailuseFalg = useCallback(
    async (productId: number) => {
      const findProductDetail: any = await getProductDetail({ productId });
      // console.log("findProductDetail", findProductDetail);
      const flag: any = {
        isMeetingRoom: false,
        isRefreshRoom: false,
        isDesk: false,
        isUseOmega: false,
      };

      if (findProductDetail.status >= 200 && findProductDetail.status <= 299) {
        flag.isMeetingRoom = findProductDetail.data.data.content.isMeetingRoom;
        flag.isRefreshRoom = findProductDetail.data.data.content.isRefreshRoom;
        flag.isDesk = findProductDetail.data.data.content.isDesk;
        flag.isUseOmega = findProductDetail.data.data.content.isOmega;
      }

      return flag;
    },
    [getProductDetail],
  );

  useEffect(() => {
    if (basicInfo) {
      callOperation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basicInfo]);

  const moveUserList = useCallback(() => {
    return pagePath.user.list + "?contractApplyNumber=" + basicInfo?.contractApplyNumber;
  }, [basicInfo?.contractApplyNumber]);

  const moveVisitorList = useCallback(() => {
    return pagePath.visitor.list + "?contractApplyNumber=" + basicInfo?.contractApplyNumber;
  }, [basicInfo?.contractApplyNumber]);

  const moveProductAccessGroup = useCallback(() => {
    return pagePath.product.detail.replace(":id", operation?.spaceProductId) + "?tab=accessGroup";
  }, [operation?.spaceProductId]);

  const moveDetail = useCallback(() => {
    // console.log("params", params.id);
    let moveId = params.id;
    delete params.id;
    const path =
      pagePath.contract.detail.replace(":id", String(moveId)) +
      "?" +
      decodeURIComponent(qs.stringify(params, { allowDots: true, encode: true }));
    navigate(path);
  }, [navigate, params]);

  const moveList = useCallback(() => {
    delete params.id;
    const path =
      pagePath.contract.list +
      "?" +
      decodeURIComponent(qs.stringify(params, { allowDots: true, encode: true }));
    navigate(path);
  }, [navigate, params]);

  const openModal = useCallback((type: string) => {
    setFacilityType(type);
    setIsOpen(true);
  }, []);

  const savePrFacilities = useCallback(
    async (selected: any) => {
      setLoadingBar(true);
      setIsOpen(false);
      console.log("selected", selected);
      if (selected.length > 0) {
        // console.log("selected", selected);

        const temp = _.cloneDeep(operationOriginal);

        const checker: any = selected
          .map((facility: any) => {
            const finded = temp.ctProductFacility.find((temp: any) => temp.id === facility.id);
            if (!finded) {
              return facility;
            }
          })
          .filter((ch: any) => ch !== undefined);

        const savedFacilities: any = await insertOrUpdate({
          id: Number(params.id),
          body: { productFacility: checker },
        });
        if (savedFacilities.status >= 200 && savedFacilities.status <= 299) {
          setIsSaved(true);
        } else {
          let message = errorMessageHandler(
            savedFacilities.status,
            savedFacilities.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = savedFacilities.data.meta.errorCode
            ? savedFacilities.data.meta.errorCode
            : savedFacilities.status;
          openErrorModal(message, code);
        }
      }
      setLoadingBar(false);
    },
    [insertOrUpdate, openErrorModal, operationOriginal, params],
  );

  const openDeletePop = useCallback((id: number) => {
    setIsDelete(true);
    setDeletedId(id);
  }, []);

  const deleteItem = useCallback(async () => {
    const id = deletedId;
    const temp = _.cloneDeep(operation);
    setLoadingBar(true);
    const nwArr = temp.ctProductFacility.map((op: any) => {
      if (Number(op.id) === Number(id)) {
        op.isDeleted = true;
      }
      return op;
    });

    temp.productFacility = nwArr;
    const savedFacilities: any = await insertOrUpdate({
      id: Number(params.id),
      body: { productFacility: nwArr },
    });
    if (savedFacilities.status >= 200 && savedFacilities.status <= 299) {
      setIsSaved(true);
    } else {
      let message = errorMessageHandler(
        savedFacilities.status,
        savedFacilities.data.meta.errorCode,
        contract_bill_user_errors,
      );
      let code = savedFacilities.data.meta.errorCode
        ? savedFacilities.data.meta.errorCode
        : savedFacilities.status;
      openErrorModal(message, code);
    }
    setIsDelete(false);
    setLoadingBar(false);
  }, [deletedId, insertOrUpdate, openErrorModal, operation, params, setLoadingBar]);

  const checkUseCalendar = useMemo(() => {
    let isUseCalendar = _.cloneDeep(calendarInfoStatus);
    if ((calendarDataOri?.data === undefined || calendarDataOri?.data) && calendarData?.data) {
      isUseCalendar = CalendarInfoStatus.EDIT;
    }

    if (calendarDataOri?.data && calendarData?.data === undefined) {
      isUseCalendar = CalendarInfoStatus.DELETE;
    }
    setCalendarInfoStatus(isUseCalendar);
    return isUseCalendar;
  }, [calendarInfoStatus, calendarDataOri, calendarData]);

  const saveData = useCallback(
    async (operation: any, id: number) => {
      // console.log("operation", operation);
      const dump: any = _.cloneDeep(operation);

      let sendData: any = {};
      if (sendData) {
        setLoadingBar(true);
        const res: any = await editOper({ id, body: dump });
        if (res.status >= 200 && res.status <= 299) {
          setIsSaved(true);
        } else {
          let message = errorMessageHandler(
            res.status,
            res.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = res.data.meta.errorCode ? res.data.meta.errorCode : res.status;
          openErrorModal(message, code);
        }
      }
      const sendList: any = operation?.ctProductFacility.filter((ct: any) => {
        const find = operationOriginal.ctProductFacility.find((oct: any) => oct.id === ct.id);
        if (!_.isEqual(ct, find)) {
          return ct;
        }
      });

      if (sendList.length > 0) {
        const savedFacilities: any = await insertOrUpdate({
          id: Number(params.id),
          body: { productFacility: sendList },
        });
        if (savedFacilities.status >= 200 && savedFacilities.status <= 299) {
          setIsSaved(true);
        } else {
          let message = errorMessageHandler(
            savedFacilities.status,
            savedFacilities.data.meta.errorCode,
            contract_bill_user_errors,
          );
          let code = savedFacilities.data.meta.errorCode
            ? savedFacilities.data.meta.errorCode
            : savedFacilities.status;
          openErrorModal(message, code);
        }
      }

      setLoadingBar(false);
    },
    [setLoadingBar, editOper, openErrorModal, operationOriginal, insertOrUpdate, params],
  );

  const editOperation = useCallback(async () => {
    let checkUpdatedCal = true;
    if (checkUseCalendar === CalendarInfoStatus.EDIT) {
      const sendData = _.cloneDeep(calendarData);
      sendData.contractManageId = String(basicInfo?.contractManageId);

      if (sendData.data!.resources && sendData.data!.resources.length > 0) {
        sendData.data!.resources = sendData.data!.resources.filter(
          (rs: OutlookResource) => rs.email !== "",
        );
      }
      if (sendData.data!.service !== CalendarServiceType.OUTLOOK) {
        delete sendData.data!.outlook;
      }

      if (calendarData.data) {
        const saveCalendar: any = await editCalendarInfo(sendData);
        if (saveCalendar.status > 399 || saveCalendar.status < 199) {
          checkUpdatedCal = false;
          return;
        }
      }
    }
    if (checkUseCalendar === CalendarInfoStatus.DELETE) {
      const rmCalInfo: any = await removeCalendarInfo({
        contractId: String(calendarData!.contractId!),
      });

      if (rmCalInfo.status > 399 || rmCalInfo.status < 199) {
        checkUpdatedCal = false;
        return;
      }
    }

    operation.productManage.memberMaxNums = Number(operation.productManage?.memberMaxNums);
    operation.productManage.meetingRoomTotalTime = Number(
      operation.productManage?.meetingRoomTotalTime,
    );
    operation.productManage.refreshRoomTotalTime = Number(
      operation.productManage?.refreshRoomTotalTime,
    );
    operation.productManage.deskTotalTime = Number(operation.productManage?.deskTotalTime);

    operation.productManage.meetingRoomDailyTime = Number(
      operation.productManage?.meetingRoomDailyTime,
    );
    operation.productManage.refreshRoomDailyTime = Number(
      operation.productManage?.refreshRoomDailyTime,
    );
    operation.productManage.deskDailyTime = Number(operation.productManage?.deskDailyTime);
    //

    let temp = _.cloneDeep(operation);
    temp.productManage = Object.assign(operation?.productManage, {
      calendarType: calendarData.data?.service === CalendarServiceType.GOOGLE ? "GC" : "OC",
    });

    if (checkUseCalendar === CalendarInfoStatus.DELETE) {
      temp.productManage = Object.assign(operation?.productManage, {
        isUseCalendar: false,
      });
    }

    if (operation.productFacility.length !== prFacilities.length) {
      prFacilities.forEach((pr: any) => {
        const finded = operation.productFacility.find((pa: any) => pa.id === pr.id);
        if (finded === undefined) {
          pr.isDeleted = true;
          pr.contractFacilityId = 0;
          temp.productFacility.push(pr);
        }
      });
    }

    // console.log("checkUpdatedCal", operation.productManage.isUseCalendar, checkUpdatedCal);
    if (checkUpdatedCal) {
      saveData(temp, Number(params.id));
    }
    //저장 시 각 공용공간의 데이터 중 공간상품에만 있는 데이터를 계약 쪽에 추가하여 전달해야함
  }, [
    basicInfo?.contractManageId,
    calendarData,
    checkUseCalendar,
    editCalendarInfo,
    operation,
    params.id,
    prFacilities,
    removeCalendarInfo,
    saveData,
  ]);

  return (
    <div className="contents-container__wrap detail-basic-info">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="운영"
          id={basicInfo?.contractId}
          noTitle="신청번호"
          no={basicInfo?.contractApplyNumber}
          status={basicInfo?.step}
        />
        <article className="pb20">
          <div className="contents-container__1200 ">
            <div className="contents-container__sub-title">
              <h2>이용그룹</h2>
            </div>
            <div className="flex-center">
              {/* 이용자 수 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <span className="no-wrap">
                    <span className="font14">이용자 수(현재/최대)</span>
                  </span>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start ">
                    <Link
                      to={moveUserList()}
                      target="_blank"
                      className="font14 text-hilight no-wrap"
                    >
                      이용자 목록
                    </Link>
                    <div className="minmax60 ml16">
                      <BaseInput value={operation?.productManage?.memberNums} readonly={true} />
                    </div>
                    <span className="mx10">명 /</span>
                    <div className="minmax100">
                      <BaseInput
                        type="number"
                        value={String(operation?.productManage?.memberMaxNums)}
                        onChange={(value: string) => {
                          console.log("value", value);

                          const temp: any = _.cloneDeep(operation);
                          temp.productManage.memberMaxNums = value;
                          setOperation(temp);
                          setError1("");
                        }}
                        disabled={true}
                        errorText={error1}
                      />
                    </div>
                    <span className="ml10">명</span>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex-center">
              {/* 방문자 */}
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>방문자</p>
                </div>
                <div className="contents-container__grid-contents">
                  <p>
                    <Link to={moveVisitorList()} target="_blank" className="text-hilight font14">
                      방문자 목록
                    </Link>
                  </p>
                </div>
              </section>
            </div>
          </div>
        </article>
        <article className="contents-container__divide-top">
          <div className="contents-container__1200 ">
            <div className="contents-container__sub-title">
              <h2>공용 공간 / 시간(월) 할당</h2>
            </div>
            <section className={"contents-container__grid pb20"}>
              <div className="contents-container__grid-index">
                <p>캘린더 연동</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <BaseToggle
                    checked={useCalendar}
                    name="calendar_use"
                    onChange={async (value: boolean) => {
                      const temp = _.cloneDeep(operation);

                      temp.productManage = Object.assign(operation?.productManage, {
                        isUseCalendar: value,
                      });
                      setOperation(temp);
                      setCalendar(value);
                      const tempCal = _.cloneDeep(calendarData);
                      if (value === false) {
                        delete tempCal.data;
                        setCalendarData(tempCal);
                      } else {
                        const data = {
                          service: CalendarServiceType.GOOGLE,
                          dummyUserEmail: "",
                          outlook: {
                            registeredApp: {
                              tenantId: "",
                              clientId: "",
                              clientCredentials: {
                                clientSecret: {
                                  value: "",
                                },
                              },
                            },
                          },
                        };
                        tempCal.data = data;
                        setCalendarData(tempCal);
                      }
                    }}
                  />
                </div>
                <div className={calendarData?.data && useCalendar === true ? "" : " not-available"}>
                  {calendarData?.data && useCalendar === true && (
                    <>
                      <div className="flex-row flex-center-start mb10">
                        <div className="minmax150 font14 flex-center">
                          <p className="required">Calendar Type</p>
                        </div>

                        <div className="minmax300 font14 flex-center">
                          <BaseRadio
                            id={"cal_type1"}
                            name={"cal_type"}
                            label={"GC(Google Calendar)"}
                            checked={calendarData?.data?.service === CalendarServiceType.GOOGLE}
                            onChange={() => {
                              const temp = _.cloneDeep(calendarData!);
                              temp.data!.service = CalendarServiceType.GOOGLE;
                              delete temp.data!.outlook;
                              setCalendarData(temp);
                            }}
                          />
                          <BaseRadio
                            id={"cal_type2"}
                            name={"cal_type"}
                            label={"OC(Outlook Calendar)"}
                            className="ml20"
                            checked={calendarData?.data?.service === CalendarServiceType.OUTLOOK}
                            onChange={() => {
                              const temp = _.cloneDeep(calendarData!);
                              temp.data!.service = CalendarServiceType.OUTLOOK;

                              temp.data!.outlook = {
                                registeredApp: {
                                  tenantId: "",
                                  clientId: "",
                                  clientCredentials: {
                                    clientSecret: {
                                      value: "",
                                    },
                                  },
                                },
                              };
                              setCalendarData(temp);
                            }}
                          />
                        </div>
                      </div>

                      <div className="flex-row flex-center-start mb10">
                        <div className="minmax150 font14 flex-center">
                          <p className="required">Dummy User Email</p>
                        </div>
                        <div className="minmax280 ">
                          <div className="font14 flex-center">
                            <BaseInput
                              value={calendarData?.data?.dummyUserEmail}
                              onChange={(value: string) => {
                                const temp = _.cloneDeep(calendarData!);
                                temp.data!.dummyUserEmail = value;
                                setCalendarData(temp);
                              }}
                              errorText={error3}
                            />
                          </div>
                        </div>
                      </div>
                      {calendarData?.data?.service === CalendarServiceType.OUTLOOK && (
                        <>
                          <div className="flex-row flex-center-start mb10">
                            <div className="minmax150 font14 flex-center">
                              <p className="required">Tenant ID</p>
                            </div>
                            <div className="minmax280 ">
                              <div className="font14 flex-center">
                                <BaseInput
                                  value={String(
                                    calendarData?.data?.outlook?.registeredApp?.tenantId,
                                  )}
                                  onChange={(value: string) => {
                                    const temp = _.cloneDeep(calendarData!);
                                    temp.data!.outlook!.registeredApp!.tenantId = value;
                                    setCalendarData(temp);
                                    const temp2 = _.cloneDeep(operation);
                                    temp2.tenantId = value;
                                    setOperation(temp2);
                                  }}
                                  errorText={error4}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex-row flex-center-start mb10">
                            <div className="minmax150 font14 flex-center">
                              <p className="required">Client ID</p>
                            </div>
                            <div className="minmax280 ">
                              <div className="font14 flex-center">
                                <BaseInput
                                  value={String(
                                    calendarData?.data?.outlook?.registeredApp?.clientId,
                                  )}
                                  onChange={(value: string) => {
                                    const temp = _.cloneDeep(calendarData!);
                                    temp.data!.outlook!.registeredApp!.clientId = value;
                                    setCalendarData(temp);
                                    const temp2 = _.cloneDeep(operation);

                                    temp2.clientId = value;

                                    setOperation(temp2);
                                  }}
                                  errorText={error5}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex-row flex-center-start">
                            <div className="minmax150 font14 flex-center">
                              <p className="required">Client Secret Value</p>
                            </div>
                            <div className="minmax280 ">
                              <div className="font14 flex-center">
                                {/* show */}
                                {useShowHide === false && (
                                  <BaseInput
                                    onIconClick={() => {
                                      setShowHide(true);
                                    }}
                                    rightIconClassName={
                                      useShowHide ? "ic-eyes-close" : "ic-eyes-open"
                                    }
                                    type="password"
                                    value={String(
                                      calendarData?.data?.outlook?.registeredApp?.clientCredentials!
                                        .clientSecret!.value,
                                    )}
                                    onChange={(value: string) => {
                                      const temp = _.cloneDeep(calendarData!);
                                      temp.data!.outlook!.registeredApp!.clientCredentials!.clientSecret!.value =
                                        value;
                                      setCalendarData(temp);
                                      const temp2 = _.cloneDeep(operation);
                                      temp2.clientSecretValue = value;
                                      setOperation(temp2);
                                    }}
                                    errorText={error6}
                                  />
                                )}
                                {/* hide */}
                                {useShowHide && (
                                  <BaseInput
                                    onIconClick={() => {
                                      setShowHide(false);
                                    }}
                                    type="text"
                                    rightIconClassName={
                                      useShowHide ? "ic-eyes-close" : "ic-eyes-open"
                                    }
                                    value={String(
                                      calendarData?.data?.outlook?.registeredApp?.clientCredentials!
                                        .clientSecret!.value,
                                    )}
                                    onChange={(value: string) => {
                                      const temp = _.cloneDeep(calendarData!);
                                      temp.data!.outlook!.registeredApp!.clientCredentials!.clientSecret!.value =
                                        value;
                                      setCalendarData(temp);
                                      const temp2 = _.cloneDeep(operation);
                                      temp2.clientSecretValue = value;
                                      setOperation(temp2);
                                    }}
                                    errorText={error6}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </section>
            {/* 미팅룸 할당 */}
            <section className={"contents-container__grid pb20"}>
              <div className="contents-container__grid-index">
                <p>회의실 예약</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <BaseToggle
                    checked={useMeet}
                    name="meeting_use"
                    onChange={async (value: boolean) => {
                      let checkValue = false;
                      if (value) {
                        const { isMeetingRoom } = await checkProductDetailuseFalg(
                          Number(application?.spaceProductId),
                        );

                        if (isMeetingRoom) {
                          checkValue = value;
                        }
                      }
                      const temp = _.cloneDeep(operation);

                      temp.productManage = Object.assign(operation?.productManage, {
                        isUseMeetingRoom: checkValue,
                      });
                      setOperation(temp);
                      setUseMeet(checkValue);
                    }}
                    className="not-available"
                    disabled={true}
                  />
                </div>
                <div className={operation?.productManage?.isUseMeetingRoom ? "" : " not-available"}>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax230 ">
                      <div className="font14 flex-center">
                        총 예약 시간/월(제한없음
                        <BaseToggle
                          checked={Number(operation?.productManage?.meetingRoomTotalTime) === -1}
                          name="meetingRoomTotalTimeToggle"
                          className="ml10 not-available"
                          disabled={true}
                          onChange={(value: boolean) => {
                            let rtn = 0;
                            if (value) {
                              rtn = -1;
                            } else {
                              if (tempOperation.productManage.meetingRoomTotalTime !== -1) {
                                rtn = tempOperation.productManage.meetingRoomTotalTime;
                              }
                            }
                            console.log("tempOperation", tempOperation);
                            const temp = _.cloneDeep(operation);

                            temp.productManage = Object.assign(operation?.productManage, {
                              meetingRoomTotalTime: rtn,
                              meetingRoomDailyTime: rtn,
                            });
                            setOperation(temp);
                          }}
                        />
                        )
                      </div>
                    </div>
                    <div className="minmax120">
                      <BaseInput
                        value={
                          String(operation?.productFacilityUsedTime?.meetingRoomMonthlyUsedTime) ||
                          "0"
                        }
                        readonly={true}
                      />
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.meetingRoomMonthlyUsedTime > 0
                        ? " ( " +
                          showHourMinute(
                            operation?.productFacilityUsedTime?.meetingRoomMonthlyUsedTime,
                          ) +
                          " ) "
                        : ""}
                    </span>
                    {Number(operation?.productManage?.meetingRoomTotalTime) !== -1 && (
                      <span className="mx10">/</span>
                    )}
                    {Number(operation?.productManage?.meetingRoomTotalTime) !== -1 ? (
                      <>
                        <div className="minmax120">
                          <BaseInput
                            type={"number"}
                            name={"meetingRoomTotalTimeInput"}
                            value={String(operation?.productManage?.meetingRoomTotalTime)}
                            onChange={(value: string) => {
                              // console.log("value", value);
                              const temp: any = _.cloneDeep(operation);
                              temp.productManage.meetingRoomTotalTime = value;
                              setOperation(temp);
                              setError2("");
                            }}
                            className="not-available"
                            disabled={true}
                            errorText={error2}
                          />
                        </div>
                        <span className="ml10">
                          분
                          {operation?.productManage?.meetingRoomTotalTime > 0
                            ? " ( " +
                              showHourMinute(operation?.productManage?.meetingRoomTotalTime) +
                              " ) "
                            : ""}
                        </span>
                      </>
                    ) : null}
                  </div>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax230">
                      <div className="font14 flex-center">
                        예약 최대 시간/일 (제한없음
                        <BaseToggle
                          checked={Number(operation?.productManage?.meetingRoomDailyTime) === -1}
                          name="meetingRoomDailyTimeToggle"
                          className="ml10 not-available"
                          disabled={true}
                          onChange={(value: boolean) => {
                            let rtn = 0;
                            if (value) {
                              rtn = -1;
                            } else {
                              if (tempOperation.productManage.meetingRoomDailyTime !== -1) {
                                rtn = tempOperation.productManage.meetingRoomDailyTime;
                              }
                            }
                            const temp = _.cloneDeep(operation);

                            temp.productManage = Object.assign(operation?.productManage, {
                              meetingRoomTotalTime: rtn,
                              meetingRoomDailyTime: rtn,
                            });
                            setOperation(temp);
                          }}
                        />
                        )
                      </div>
                    </div>
                    <div className="minmax120">
                      <BaseInput
                        value={
                          String(operation?.productFacilityUsedTime?.meetingRoomDailyUsedTime) ||
                          "0"
                        }
                        readonly={true}
                      />
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.meetingRoomDailyUsedTime > 0
                        ? " ( " +
                          showHourMinute(
                            operation?.productFacilityUsedTime?.meetingRoomDailyUsedTime,
                          ) +
                          " ) "
                        : ""}
                    </span>
                    {Number(operation?.productManage?.meetingRoomDailyTime) !== -1 && (
                      <span className="mx10">/</span>
                    )}
                    {Number(operation?.productManage?.meetingRoomDailyTime) !== -1 ? (
                      <>
                        <div className="minmax120">
                          <BaseInput
                            type={"number"}
                            name={"meetingRoomDailyTimeInput"}
                            value={String(operation?.productManage?.meetingRoomDailyTime)}
                            onChange={(value: string) => {
                              // console.log("value", value);
                              const temp: any = _.cloneDeep(operation);
                              temp.productManage.meetingRoomDailyTime = value;
                              setOperation(temp);
                              setError2("");
                            }}
                            className="not-available"
                            disabled={true}
                            errorText={error2}
                          />
                        </div>
                        <span className="ml10">
                          분
                          {operation?.productManage?.meetingRoomDailyTime > 0
                            ? " ( " +
                              showHourMinute(operation?.productManage?.meetingRoomDailyTime) +
                              " ) "
                            : ""}
                        </span>
                      </>
                    ) : null}
                  </div>
                  <table className="inner-table mt10">
                    <thead>
                      <tr>
                        <th className="minmax70 text-center">
                          <span>ID</span>
                        </th>
                        <th className="minmax90 text-center">
                          <span>회의실 명</span>
                        </th>

                        <th className="minmax70 text-center">
                          <span>정원</span>
                        </th>

                        <th className="minmax70 text-center">
                          <span>지상/지하</span>
                        </th>
                        <th className="minmax70 text-center">
                          <span>위치</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>건물</span>
                        </th>
                        <th className="minmax250 text-center">
                          <span>리소스 이메일</span>
                        </th>
                        <th>
                          <div className="minmax60">
                            <button
                              className="base-add-btn"
                              disabled={
                                !operation?.productManage?.isUseMeetingRoom ||
                                basicInfo?.contractStep === "APPLY_CANCEL" ||
                                basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                                basicInfo?.contractStep === "APPLY_CONFIRM" ||
                                basicInfo?.contractStep === "USE_COMPLETE"
                              }
                              onClick={() => openModal("MEETING_ROOM")}
                            ></button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {operation?.ctProductFacility.map((mr: any, index: number) => {
                        if (mr.commonFacilityType === "MEETING_ROOM" && mr.isDeleted === false) {
                          const finded = calendarData.data?.resources?.find(
                            (rs: OutlookResource) => Number(rs.facilityId) === Number(mr.id),
                          );

                          return (
                            <tr key={index}>
                              <td className="text-center">
                                <span>{mr.id}</span>
                              </td>
                              <td className="text-center">
                                <span>
                                  <BaseTooltip contents={mr.facilityName} />
                                </span>
                              </td>

                              <td className="text-center">
                                <span>{mr.facilityMaxPeopleNums}인</span>
                              </td>

                              <td className="text-center">
                                <span>{mr.isGround ? "지상" : "지하"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.floorNum + "층"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.buildingName}</span>
                              </td>
                              <td>
                                <div className="flex-center-center">
                                  {calendarData?.data && useCalendar ? (
                                    <BaseInput
                                      value={String(finded?.email ? finded?.email : "")}
                                      onChange={(value: string) => {
                                        console.log("value", value);
                                        const temp = _.cloneDeep(calendarData);
                                        if (finded) {
                                          console.log("있다.");
                                          temp.data?.resources?.forEach((rs: OutlookResource) => {
                                            if (Number(rs.facilityId) === Number(mr.id)) {
                                              rs.email = value;
                                            }
                                          });
                                        } else {
                                          console.log("없다.");
                                          temp.data = {
                                            ...temp.data,
                                            ...{ resources: [{ facilityId: mr.id, email: value }] },
                                          };
                                        }

                                        console.log("temp", temp);
                                        setCalendarData(temp);
                                      }}
                                    />
                                  ) : (
                                    <p>-</p>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="flex-center-center">
                                  <button
                                    className="base-erase-btn"
                                    disabled={
                                      !operation?.productManage?.isUseMeetingRoom ||
                                      basicInfo?.contractStep === "APPLY_CANCEL" ||
                                      basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                                      basicInfo?.contractStep === "APPLY_CONFIRM" ||
                                      basicInfo?.contractStep === "USE_COMPLETE"
                                    }
                                    onClick={() => openDeletePop(mr.id)}
                                  ></button>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            {/* 리프레시룸 예약 예약 */}
            <section className={"contents-container__grid pb20"}>
              <div className="contents-container__grid-index">
                <p>편의시설 예약</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <BaseToggle
                    checked={useRefresh}
                    name="refresh_use"
                    onChange={async (value: boolean) => {
                      let checkValue = false;
                      if (value) {
                        const { isRefreshRoom } = await checkProductDetailuseFalg(
                          Number(application?.spaceProductId),
                        );

                        if (isRefreshRoom) {
                          checkValue = value;
                        }
                      }
                      const temp = _.cloneDeep(operation);

                      temp.productManage = Object.assign(operation?.productManage, {
                        isUseRefreshRoom: checkValue,
                      });
                      setOperation(temp);
                      setUseRefresh(checkValue);
                    }}
                    className="not-available"
                    disabled={true}
                  />
                </div>
                <div className={operation?.productManage?.isUseRefreshRoom ? "" : " not-available"}>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax230">
                      <div className="font14 flex-center">
                        총 예약 시간/월(제한없음
                        <BaseToggle
                          checked={Number(operation?.productManage?.refreshRoomTotalTime) === -1}
                          name="refreshRoomTotalTimeToggle"
                          className="ml10 not-available"
                          disabled={true}
                          onChange={(value: boolean) => {
                            let rtn = 0;
                            if (value) {
                              rtn = -1;
                            } else {
                              if (tempOperation.productManage.refreshRoomTotalTime !== -1) {
                                rtn = tempOperation.productManage.refreshRoomTotalTime;
                              }
                            }
                            const temp = _.cloneDeep(operation);

                            temp.productManage = Object.assign(operation?.productManage, {
                              refreshRoomTotalTime: rtn,
                              refreshRoomDailyTime: rtn,
                            });
                            setOperation(temp);
                          }}
                        />
                        )
                      </div>
                    </div>
                    <div className="minmax120">
                      <BaseInput
                        value={
                          String(operation?.productFacilityUsedTime?.refreshRoomMonthlyUsedTime) ||
                          "0"
                        }
                        readonly={true}
                      />
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.refreshRoomMonthlyUsedTime > 0
                        ? " ( " +
                          showHourMinute(
                            operation?.productFacilityUsedTime?.refreshRoomMonthlyUsedTime,
                          ) +
                          " ) "
                        : ""}
                    </span>
                    {Number(operation?.productManage?.refreshRoomTotalTime) !== -1 && (
                      <span className="mx10">/</span>
                    )}

                    {Number(operation?.productManage?.refreshRoomTotalTime) !== -1 ? (
                      <>
                        <div className="minmax120">
                          <BaseInput
                            type={"number"}
                            name={"refreshRoomTotalTimeInput"}
                            value={String(operation?.productManage?.refreshRoomTotalTime)}
                            onChange={(value: string) => {
                              // console.log("value", value);
                              const temp: any = _.cloneDeep(operation);
                              temp.productManage.refreshRoomTotalTime = value;
                              setOperation(temp);
                              setError2("");
                            }}
                            className="not-available"
                            disabled={true}
                            errorText={error2}
                          />
                        </div>
                        <span className="ml10">
                          분
                          {operation?.productManage?.refreshRoomTotalTime > 0
                            ? " ( " +
                              showHourMinute(operation?.productManage?.refreshRoomTotalTime) +
                              " ) "
                            : ""}
                        </span>
                      </>
                    ) : null}
                  </div>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax230">
                      <div className="font14 flex-center">
                        예약 최대 시간/일(제한없음
                        <BaseToggle
                          checked={Number(operation?.productManage?.refreshRoomDailyTime) === -1}
                          name="refreshRoomDailyTimeToggle"
                          className="ml10 not-available"
                          disabled={true}
                          onChange={(value: boolean) => {
                            let rtn = 0;
                            if (value) {
                              rtn = -1;
                            } else {
                              if (tempOperation.productManage.refreshRoomDailyTime !== -1) {
                                rtn = tempOperation.productManage.refreshRoomDailyTime;
                              }
                            }
                            const temp = _.cloneDeep(operation);

                            temp.productManage = Object.assign(operation?.productManage, {
                              refreshRoomTotalTime: rtn,
                              refreshRoomDailyTime: rtn,
                            });
                            setOperation(temp);
                          }}
                        />
                        )
                      </div>
                    </div>
                    <div className="minmax120">
                      <BaseInput
                        value={
                          String(operation?.productFacilityUsedTime?.refreshRoomDailyUsedTime) ||
                          "0"
                        }
                        readonly={true}
                      />
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.refreshRoomDailyUsedTime > 0
                        ? " ( " +
                          showHourMinute(
                            operation?.productFacilityUsedTime?.refreshRoomDailyUsedTime,
                          ) +
                          " ) "
                        : ""}
                    </span>
                    {Number(operation?.productManage?.refreshRoomDailyTime) !== -1 && (
                      <span className="mx10">/</span>
                    )}

                    {Number(operation?.productManage?.refreshRoomDailyTime) !== -1 ? (
                      <>
                        <div className="minmax120">
                          <BaseInput
                            type={"number"}
                            name={"refreshRoomDailyTimeInput"}
                            value={String(operation?.productManage?.refreshRoomDailyTime)}
                            onChange={(value: string) => {
                              // console.log("value", value);
                              const temp: any = _.cloneDeep(operation);
                              temp.productManage.refreshRoomDailyTime = value;
                              setOperation(temp);
                              setError2("");
                            }}
                            className="not-available"
                            disabled={true}
                            errorText={error2}
                          />
                        </div>
                        <span className="ml10">
                          분
                          {operation?.productManage?.refreshRoomDailyTime > 0
                            ? " ( " +
                              showHourMinute(operation?.productManage?.refreshRoomDailyTime) +
                              " ) "
                            : ""}
                        </span>
                      </>
                    ) : null}
                  </div>
                  <table className="inner-table mt10">
                    <thead>
                      <tr>
                        <th className="minmax70 text-center">
                          <span>ID</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>편의시설 명</span>
                        </th>

                        <th className="minmax140 text-center">
                          <span>설명</span>
                        </th>
                        <th className="minmax90 text-center">
                          <span>지상/지하</span>
                        </th>
                        <th className="minmax90 text-center">
                          <span>위치</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>건물</span>
                        </th>
                        <th>
                          <div className="minmax60">
                            {/* <button
                              className="base-add-btn"
                              disabled={
                                !operation?.productManage?.isUseRefreshRoom ||
                                basicInfo?.contractStep === "APPLY_CANCEL" ||
                                basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                                basicInfo?.contractStep === "APPLY_CONFIRM" ||
                                basicInfo?.contractStep === "USE_COMPLETE"
                              }
                              onClick={() => openModal("REFRESH_ROOM")}
                            ></button> */}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {operation?.ctProductFacility.map((mr: any, index: number) => {
                        if (mr.commonFacilityType === "REFRESH_ROOM" && mr.isDeleted === false) {
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                <span>{mr.id}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.facilityName}</span>
                              </td>

                              <td className="text-center">
                                <div className="minmax220">
                                  <span className="ellipsis">
                                    {mr.description ? (
                                      <BaseTooltip contents={mr.description} />
                                    ) : (
                                      "-"
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td className="text-center">
                                <span>{mr.isGround ? "지상" : "지하"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.floorNum + "층"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.buildingName}</span>
                              </td>
                              <td>
                                <div className="flex-center-center">
                                  {/* <button
                                    className="base-erase-btn"
                                    disabled={
                                      !operation?.productManage?.isUseRefreshRoom ||
                                      basicInfo?.contractStep === "APPLY_CANCEL" ||
                                      basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                                      basicInfo?.contractStep === "APPLY_CONFIRM" ||
                                      basicInfo?.contractStep === "USE_COMPLETE"
                                    }
                                    onClick={() => openDeletePop(mr.id)}
                                  ></button> */}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            {/* 1인 데스크 할달 */}

            <section className={"contents-container__grid pb20"}>
              <div className="contents-container__grid-index">
                <p>좌석 예약</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <BaseToggle
                    checked={useDesk}
                    name="desk_use"
                    onChange={async (value: boolean) => {
                      let checkValue = false;
                      if (value) {
                        const { isDesk } = await checkProductDetailuseFalg(
                          Number(application?.spaceProductId),
                        );

                        if (isDesk) {
                          checkValue = value;
                        }
                      }
                      const temp = _.cloneDeep(operation);

                      temp.productManage = Object.assign(operation?.productManage, {
                        isUseDesk: checkValue,
                      });
                      setOperation(temp);
                      setUseDesk(checkValue);
                    }}
                    className="not-available"
                    disabled={true}
                  />
                </div>
                <div className={operation?.productManage?.isUseDesk ? "" : " not-available"}>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax230">
                      <div className="font14 flex-center">
                        총 예약 시간/월(제한없음
                        <BaseToggle
                          checked={Number(operation?.productManage?.deskTotalTime) === -1}
                          name="deskTotalTimeToggle"
                          className="ml10 not-available"
                          disabled={true}
                          onChange={(value: boolean) => {
                            let rtn = 0;
                            if (value) {
                              rtn = -1;
                            } else {
                              if (tempOperation.productManage.deskTotalTime !== -1) {
                                rtn = tempOperation.productManage.deskTotalTime;
                              }
                            }
                            const temp = _.cloneDeep(operation);

                            temp.productManage = Object.assign(operation?.productManage, {
                              deskTotalTime: rtn,
                              deskDailyTime: rtn,
                            });
                            setOperation(temp);
                          }}
                        />
                        )
                      </div>
                    </div>
                    <div className="minmax120">
                      <BaseInput
                        value={
                          String(operation?.productFacilityUsedTime?.deskMonthlyUsedTime) || "0"
                        }
                        readonly={true}
                      />
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.deskMonthlyUsedTime > 0
                        ? " ( " +
                          showHourMinute(operation?.productFacilityUsedTime?.deskMonthlyUsedTime) +
                          " ) "
                        : ""}
                    </span>
                    {Number(operation?.productManage?.deskTotalTime) !== -1 && (
                      <span className="mx10">/</span>
                    )}

                    {Number(operation?.productManage?.deskTotalTime) !== -1 ? (
                      <>
                        <div className="minmax120">
                          <BaseInput
                            type={"number"}
                            name={"deskTotalTimeInput"}
                            value={String(operation?.productManage?.deskTotalTime)}
                            onChange={(value: string) => {
                              // console.log("value", value);
                              const temp: any = _.cloneDeep(operation);
                              temp.productManage.deskTotalTime = value;
                              setOperation(temp);
                              setError2("");
                            }}
                            className="not-available"
                            disabled={true}
                            errorText={error2}
                          />
                        </div>
                        <span className="ml10">
                          분
                          {operation?.productManage?.deskTotalTime > 0
                            ? " ( " +
                              showHourMinute(operation?.productManage?.deskTotalTime) +
                              " ) "
                            : ""}
                        </span>
                      </>
                    ) : null}
                  </div>
                  <div className="flex-row flex-center-start ">
                    <div className="minmax230">
                      <div className="font14 flex-center">
                        예약 최대 시간/일(제한없음
                        <BaseToggle
                          checked={Number(operation?.productManage?.deskDailyTime) === -1}
                          name="deskDailyTimeToggle"
                          className="ml10 not-available"
                          disabled={true}
                          onChange={(value: boolean) => {
                            let rtn = 0;
                            if (value) {
                              rtn = -1;
                            } else {
                              if (tempOperation.productManage.deskDailyTime !== -1) {
                                rtn = tempOperation.productManage.deskDailyTime;
                              }
                            }
                            const temp = _.cloneDeep(operation);

                            temp.productManage = Object.assign(operation?.productManage, {
                              deskTotalTime: rtn,
                              deskDailyTime: rtn,
                            });
                            setOperation(temp);
                          }}
                        />
                        )
                      </div>
                    </div>
                    <div className="minmax120">
                      <BaseInput
                        value={String(operation?.productFacilityUsedTime?.deskDailyUsedTime) || "0"}
                        readonly={true}
                      />
                    </div>
                    <span className="mx10">분</span>
                    <span className="">
                      {operation?.productFacilityUsedTime?.deskDailyUsedTime > 0
                        ? " ( " +
                          showHourMinute(operation?.productFacilityUsedTime?.deskDailyUsedTime) +
                          " ) "
                        : ""}
                    </span>
                    {Number(operation?.productManage?.deskDailyTime) !== -1 && (
                      <span className="mx10">/</span>
                    )}

                    {Number(operation?.productManage?.deskDailyTime) !== -1 ? (
                      <>
                        <div className="minmax120">
                          <BaseInput
                            type={"number"}
                            name={"deskDailyTimeInput"}
                            value={String(operation?.productManage?.deskDailyTime)}
                            onChange={(value: string) => {
                              // console.log("value", value);
                              const temp: any = _.cloneDeep(operation);
                              temp.productManage.deskDailyTime = value;
                              setOperation(temp);
                              setError2("");
                            }}
                            className="not-available"
                            disabled={true}
                            errorText={error2}
                          />
                        </div>
                        <span className="ml10">
                          분
                          {operation?.productManage?.deskDailyTime > 0
                            ? " ( " +
                              showHourMinute(operation?.productManage?.deskDailyTime) +
                              " ) "
                            : ""}
                        </span>
                      </>
                    ) : null}
                  </div>
                  <table className="inner-table mt10">
                    <thead>
                      <tr>
                        <th className="minmax70 text-center">
                          <span>ID</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>좌석그룹 명</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>좌석 명</span>
                        </th>

                        <th className="minmax140 text-center">
                          <span>설명</span>
                        </th>
                        <th className="minmax90 text-center">
                          <span>지상/지하</span>
                        </th>
                        <th className="minmax90 text-center">
                          <span>위치</span>
                        </th>
                        <th className="minmax100 text-center">
                          <span>건물</span>
                        </th>
                        <th>
                          <div className="minmax60">
                            {/* <button
                              className="base-add-btn"
                              disabled={
                                !operation?.productManage?.isUseDesk ||
                                basicInfo?.contractStep === "APPLY_CANCEL" ||
                                basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                                basicInfo?.contractStep === "APPLY_CONFIRM" ||
                                basicInfo?.contractStep === "USE_COMPLETE"
                              }
                              onClick={() => openModal("DESK")}
                            ></button> */}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {operation?.ctProductFacility.map((mr: any, index: number) => {
                        if (mr.commonFacilityType === "DESK" && mr.isDeleted === false) {
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                <span>{mr.id}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.groupName}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.facilityName}</span>
                              </td>

                              <td className="text-center">
                                <div className="minmax220">
                                  <span className="ellipsis">
                                    {mr.description ? (
                                      <BaseTooltip contents={mr.description} />
                                    ) : (
                                      "-"
                                    )}
                                  </span>
                                </div>
                              </td>
                              <td className="text-center">
                                <span>{mr.isGround ? "지상" : "지하"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.floorNum + "층"}</span>
                              </td>
                              <td className="text-center">
                                <span>{mr.buildingName}</span>
                              </td>
                              <td>
                                <div className="flex-center-center">
                                  {/* <button
                                    className="base-erase-btn"
                                    disabled={
                                      !operation?.productManage?.isUseDesk ||
                                      basicInfo?.contractStep === "APPLY_CANCEL" ||
                                      basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
                                      basicInfo?.contractStep === "APPLY_CONFIRM" ||
                                      basicInfo?.contractStep === "USE_COMPLETE"
                                    }
                                    onClick={() => openDeletePop(mr.id)}
                                  ></button> */}
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
            <section className="contents-container__grid pb20">
              <div className="contents-container__grid-index">
                <p>방문자 초대</p>
              </div>
              <div className="contents-container__grid-contents">
                <div className="flex-row flex-center-start ">
                  <BaseToggle
                    checked={useInvite}
                    name="invite_use"
                    disabled={true}
                    onChange={(value: boolean) => {
                      const temp = _.cloneDeep(operation);

                      temp.productManage = Object.assign(operation?.productManage, {
                        isUseVisitorInvite: value,
                      });
                      setOperation(temp);
                      setUseInvite(value);
                    }}
                  />
                </div>
              </div>
            </section>
          </div>
        </article>
      </div>
      <SearchContractFacilityPopup
        isOpen={isOpen}
        type={facilityType}
        operation={operation}
        setOperation={setOperation}
        contractId={Number(params?.id)}
        onClick={savePrFacilities}
        onClose={() => setIsOpen(false)}
      />
      <BaseModal
        isOpen={isDelete}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClick={() => deleteItem()}
        onClose={() => setIsDelete(false)}
      >
        <p>삭제 하시겠습니까?</p>
      </BaseModal>
      <BaseModal isOpen={isSaved} btnRightTitle="확인" onClick={() => moveDetail()}>
        <p>저장 되었습니다.</p>
      </BaseModal>

      <div className="contents-container__btn-wrap">
        <div className="left-area d-flex">
          <BaseButton
            title="목록으로"
            onClick={() => moveList()}
            className="color-white size-large mr8"
          />
          <BaseButton
            title="취소"
            onClick={() => moveDetail()}
            className="color-white size-large"
          />
        </div>
        <div className="right-area">
          <BaseButton
            title="저장"
            disabled={
              basicInfo?.contractStep === "APPLY_CANCEL" ||
              basicInfo?.contractStep === "TERMINATE_COMPLETE" ||
              basicInfo?.contractStep === "APPLY_CONFIRM" ||
              basicInfo?.contractStep === "USE_COMPLETE"
            }
            onClick={editOperation}
            className="size-large"
          />
        </div>
      </div>
    </div>
  );
};
export default ContractFormManage;
