import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { PagePath } from "src/pages/product/details";
import { PopbillBizDetailModel } from "src/api/popbill/popbill-types";
import { BaseTooltip } from "src/components";
import { companyRegistrationHypen } from "src/utils/common-util";

const PopbillColumns = [
  {
    Header: "ID",
    accessor: "pbBizId",
    width: 80,
    Cell: ({ row, value }: Cell<PopbillBizDetailModel>) => {
      const detailPath = `${PagePath.popbill.detail.replace(":id", String(value))}`;
      return (
        <Link to={detailPath} className="text-hilight">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "법인명/상호",
    accessor: "bizName",
    width: 150,
    Cell: ({ value }: Cell<PopbillBizDetailModel>) => (
      <span className="text-left w-100">{value || "-"}</span>
    ),
  },
  {
    Header: "사업자 등록번호",
    accessor: "bizRegNo",
    width: 140,
    Cell: ({ value }: Cell<PopbillBizDetailModel>) =>
      value ? companyRegistrationHypen(value) : "-",
  },

  {
    Header: "세금계산서 발행 API",
    accessor: "isVerifiedTaxInvoice",
    width: 180,
    Cell: ({ value }: Cell<PopbillBizDetailModel>) => (
      <span className={value ? "chip green" : "chip red"}>{value ? "연동성공" : "연동실패"}</span>
    ),
  },
  {
    Header: "현금영수증 발급 API",
    accessor: "isVerifiedCashbill",
    width: 180,
    Cell: ({ value }: Cell<PopbillBizDetailModel>) => (
      <span className={value ? "chip green" : "chip red"}>{value ? "연동성공" : "연동실패"}</span>
    ),
  },
  {
    Header: "계좌조회 API",
    accessor: "isVerifiedBankAccount",
    width: 180,
    Cell: ({ value }: Cell<PopbillBizDetailModel>) => (
      <span className={value ? "chip green" : "chip red"}>{value ? "연동성공" : "연동실패"}</span>
    ),
  },
  {
    Header: "등록 계좌",
    accessor: "popbillAcctCnt",
    width: 90,
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 120,
    Cell: ({ value }: Cell<PopbillBizDetailModel>) => {
      return <BaseTooltip contents={value || "-"} type={"date"} />;
    },
  },
  {
    Header: "최종 수정자",
    accessor: "modifiedBy",
    width: 120,
    Cell: ({ value }: Cell<PopbillBizDetailModel>) => {
      return <BaseTooltip contents={value || "-"} />;
    },
  },
];

export default PopbillColumns;
