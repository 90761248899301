import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { AccessDeviceModel } from "src/api/access/ac2-types";
import { PaRegisteredDevice } from "src/api/iot/iot-types";
import { BaseTooltip } from "src/components";
import { PagePath } from "src/pages/product/details";

interface CustomAccessDeviceListModel extends AccessDeviceModel {
  // 출입 시스템 명칭 포함
  externalServerNames?: string[];
}

const AccessDeviceListColumns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: (props: Cell<AccessDeviceModel>) => {
      const path = PagePath.accessDevice.detail.replace(":id", props.value);
      return (
        <Link to={path} className="text-hilight">
          {props.value}
        </Link>
      );
    },
  },
  {
    Header: "장치 id",
    accessor: "externalDeviceId",
    width: 140,
    Cell: (props: Cell<AccessDeviceModel>) => {
      return (
        <div className="w-100 text-left">
          <p>{props.value}</p>
        </div>
      );
    },
  },
  {
    Header: "장치 이름",
    accessor: "deviceName",
    width: 180,
    Cell: (props: Cell<AccessDeviceModel>) => {
      return (
        <div className="w-100 text-left ellipsis2">
          <p>{props.value}</p>
        </div>
      );
    },
  },
  {
    Header: "출입시스템",
    accessor: "externalServerNames",
    width: 280,
    Cell: (props: Cell<CustomAccessDeviceListModel>) => <>{props.value}</>,
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 160,
    Cell: (props: Cell<AccessDeviceModel>) => {
      return (
        <>
          <BaseTooltip contents={props.value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "최종 수정일",
    accessor: "lastModifiedDate",
    width: 160,
    Cell: (props: Cell<AccessDeviceModel>) => {
      return (
        <>
          <BaseTooltip contents={props.value} type={"date"} />
        </>
      );
    },
  },
  {
    Header: "파트너",
    accessor: "partnerIds",
    width: 140,
    Cell: (props: Cell<PaRegisteredDevice>) => {
      const firstPartnerId = props.value[0];
      const path = PagePath.partner.detail.replace(":id", firstPartnerId);
      if (props.value.length > 0) {
        return (
          <div>
            <Link to={path} target="_blank" className="text-hilight mr4">
              {firstPartnerId}
            </Link>
            {props.value.length !== 1 && <span>외 {props.value.length - 1}</span>}
          </div>
        );
      } else return <p>-</p>;
    },
  },
];

export default AccessDeviceListColumns;
