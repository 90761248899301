import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ApiPath } from "src/api";
import { ApiResponse } from "../public-types";
import {
  ContractCount,
  ContractDetailBasicInfo,
  ContractManageList,
  GetContractDashboardListParams,
  SearchParams,
} from "./contract-types";

/**
 * CCA10. 계약 페이징 리스트 조회 신청/계약 목록 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractApply = (
  axios: AxiosInstance,
  params?: SearchParams,
): Promise<AxiosResponse<ApiResponse<{ content: ContractManageList[] }>>> => {
  const path = ApiPath.contract.list;
  return axios.get<ApiResponse<{ content: ContractManageList[] }>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};
/**
 * 기본정보 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractDetailBasicInfo = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<ContractDetailBasicInfo>>> => {
  const path = ApiPath.contract.detail.basicInfo.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<ContractDetailBasicInfo>>(path, { params });
};

/**
 * 신청정보 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractApplicationInfo = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.application.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<any>>(path, { params });
};

/**
 * 계약정보 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractInfo = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.contract.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<any>>(path, { params });
};

const getContractDetailPopup = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.snapshot.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<any>>(path, { params });
};
/**
 * 2023-10-23 스냅샷 데이터 변경
 * @param axios
 * @param params
 * @returns
 */
export const getContractProductSnapshotDetailPopup = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.attributes.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<any>>(path, { params });
};

const getContractManageByContractApplyNumber = (
  axios: AxiosInstance,
  params?: {
    contractApplyNumber: string;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.findContractManageByApplyNumber.replace(
    "{contractApplyNumber}",
    params!.contractApplyNumber!,
  );
  return axios.get<ApiResponse<any>>(path);
};

/**
 * 운영정보 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractOperationInfo = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.contractManage.replace("{contractId}", String(params!.id));
  return axios.get<ApiResponse<any>>(path, { params });
};

const getContractFacilityList = (
  axios: AxiosInstance,
  body?: {
    id: number;
    params?: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  //console.log("body", body);
  let path = ApiPath.contract.detail.findContractFacilityList.replace(
    "{contractId}",
    String(body!.id),
  );

  return axios.get<ApiResponse<any>>(path);
};

/** CPB20. 건물 공용공간 상세 조회 목록 */
//searchType = id, keywordList = array<string>
export const getProductFacilityList = (
  axios: AxiosInstance,
  params?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  //console.log("body", body);
  let path = ApiPath.contract.detail.productFacilityDetailList;

  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

const saveContractOperation = (
  axios: AxiosInstance,
  params?: {
    id: number;
    body?: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.contractManage.replace("{contractId}", String(params!.id));

  return axios.post<ApiResponse<any>>(path, params?.body);
};

/**RCA37. 계약 - 운영정보 공용시설 등록/수정
 * request body
 * { productManage:{}, productFacility: [{}] }
 */
export const addNUpdateOperationData = (
  axios: AxiosInstance,
  params?: {
    id: number;
    body?: any;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.findContractFacilityList.replace(
    "{contractId}",
    String(params!.id),
  );

  return axios.post<ApiResponse<any>>(path, params?.body);
};

/**
 * 계약 생성
 * @param axios
 * @param body
 * @returns
 */
const addContractApplication = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const path = ApiPath.contract.addApplication;
  return axios.post<ApiResponse<any>>(path, body);
};

const editContractDetail = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  console.log("body", body);

  const send: any = {
    contract: body.contract,
    contractMediaList: body.contractMediaList,
    scheduleList: body.scheduleList,
    contractManage: body.contractManage,
    billContactList: body.billContactList,
  };

  if (body.convertContractor) {
    send.convertContractor = body.convertContractor;
  }
  const path = ApiPath.contract.editContract.replace("{contractId}", String(body!.contractId));
  return axios.patch<ApiResponse<any>>(path, send);
};

const changeContractStatus = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const send = {
    contract: {
      contractStep: body?.contractStep,
    },
  };
  const path = ApiPath.contract.changeContractStatus.replace(
    "{contractId}",
    String(body!.contractId),
  );
  return axios.patch<ApiResponse<any>>(path, send);
};

/**
 * 관리자 메모 조회
 * @param axios
 * @param params
 * @returns
 */
const getContractMemoList = (
  axios: AxiosInstance,
  params?: {
    id: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const changeId = ApiPath.contract.detail.memo.list.replace("{serviceId}", String(params!.id));
  const path = changeId.replace("{serviceType}", "CT_CONTRACT_MANAGE");
  return axios.get<ApiResponse<any>>(path, { params });
};

const addContractMemo = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const send = { memoContent: body.contractMemo };
  const changeId = ApiPath.contract.detail.memo.add.replace(
    "{serviceId}",
    String(body!.contractManageId),
  );
  const path = changeId.replace("{serviceType}", "CT_CONTRACT_MANAGE");
  return axios.post<ApiResponse<any>>(path, { contractMemo: { ...send } });
};

const editContractMemo = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  // console.log('body',body);
  const send = {
    contractMemoId: body.contractMemoId,
    memoContent: body.contractMemo,
    isDeleted: "false",
  };
  const path = ApiPath.contract.detail.memo.edit.replace("{memoId}", String(body!.contractMemoId));
  return axios.patch<ApiResponse<any>>(path, { contractMemo: send });
};

/**
 * 회원의 관리자 메모 삭제
 * @param axios
 * @param body
 * @returns
 */
const deleteContractMemo = async (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const send = {
    id: body.contractMemoId,
    isDeleted: "true",
  };
  const path = ApiPath.contract.detail.memo.edit.replace("{memoId}", String(body!.contractMemoId));
  return axios.patch<ApiResponse<any>>(path, { contractMemo: send });
};

/**
 * 관리자 메모 조회
 * @param axios
 * @param params
 * @returns
 */
const getProviderAccountCredits = (
  axios: AxiosInstance,
  params?: {
    scheduleId: number;
    startDateTime: string;
    endDateTime: string;
    isBillConfirm: boolean;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.findProviderAccountCredit.replace(
    "{scheduleId}",
    String(params!.scheduleId),
  );

  return axios.get<ApiResponse<any>>(path, { params });
};

const getContractNumbers = (
  axios: AxiosInstance,
  params?: {
    searchType?: string;
    searchValue?: string;
    contractManageId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.getContractNumbers.replace(
    "{contractManageId}",
    String(params!.contractManageId),
  );
  return axios.get<ApiResponse<any>>(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

const getContractBuildingRooms = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.getRoom.replace("{contractId}", String(params!.contractId));
  return axios.get<ApiResponse<any>>(path);
};

export const getContractDashboardList = (
  axios: AxiosInstance,
  params?: GetContractDashboardListParams,
): Promise<AxiosResponse<ApiResponse<{ contractCountList: ContractCount[] }>>> => {
  const path = ApiPath.contract.dashboard.list;
  return axios.get(path, {
    params,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

//CRV31. 캘린더 연동 정보 조회
export const getCalendarInfo = (
  axios: AxiosInstance,
  params?: {
    contractId: number;
  },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.calendar.replace("{contractId}", String(params!.contractId));
  return axios.get<ApiResponse<any>>(path);
};

//CRV32. 캘린더 연동 정보 저장 처리
export const saveCalendarInfo = (
  axios: AxiosInstance,
  body?: any,
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.calendar.replace("{contractId}", String(body!.contractId));
  return axios.put<ApiResponse<any>>(path, { ...body.data });
};
//CRV33. 캘린더 연동 정보 삭제 처리
export const delCalendarInfo = (
  axios: AxiosInstance,
  body?: { contractId: string },
): Promise<AxiosResponse<ApiResponse<any>>> => {
  const path = ApiPath.contract.detail.calendar.replace("{contractId}", String(body!.contractId));
  return axios.delete<ApiResponse<any>>(path);
};

export {
  getContractApply,
  getContractDetailBasicInfo,
  getContractApplicationInfo,
  getContractDetailPopup,
  getContractOperationInfo,
  getContractInfo,
  getContractMemoList,
  addContractApplication,
  editContractDetail,
  changeContractStatus,
  getContractManageByContractApplyNumber,
  getContractFacilityList,
  saveContractOperation,
  addContractMemo,
  editContractMemo,
  deleteContractMemo,
  getProviderAccountCredits,
  getContractNumbers,
  getContractBuildingRooms,
};
