import { useEffect, useRef, useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { Cell } from "react-table";
import { getAccessDeviceListAsync } from "src/api/access/ac2-api";
import { AccessDeviceModel } from "src/api/access/ac2-types";
import useApiOperation from "src/api/hooks/api-operation";
import { BaseButton, BaseCheckbox, BaseInput, BaseModal, BasePagination } from "src/components";
import ViewDataTable from "src/pages/building/components/ViewDataTable";

interface Props {
  onCanceled: () => void;
  onAdded: (deviceList: AccessDeviceModel[]) => void;
  defaultValues: AccessDeviceModel[];
  multiSelect?: boolean;
  partnerId?: string;
}

interface CellProps extends Cell<AccessDeviceModel> {
  checked: boolean;
}

const DEVICE_TABLE_COLUMNS = [
  {
    Header: "선택",
    accessor: "checked",
    width: 100,
    Cell: (props: CellProps) => {
      const id = props.row.cells[1].value;
      return (
        <div data-device-id={id} data-checked={props.value} className="checkbox">
          <BaseCheckbox id={""} name={""} checked={props.value} />
        </div>
      );
    },
  },
  {
    Header: "id",
    accessor: "id",
    width: 100,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "장치 id",
    accessor: "externalDeviceId",
    width: 250,
    Cell: (props: CellProps) => props.value,
  },
  {
    Header: "장치 이름",
    accessor: "deviceName",
    width: 250,
    Cell: (props: CellProps) => <p className="w-100 ellipsis2">{props.value}</p>,
  },
];

function DeviceSelectModal({ onCanceled, onAdded, defaultValues, multiSelect, partnerId }: Props) {
  const [searchedWord, setSearchedWord] = useState("");
  const [page, setPage] = useState({ current: 0, total: 0 });
  const [deviceList, setDeviceList] = useState<AccessDeviceModel[]>([]);
  const [selectedDeviceList, setSelectedDeviceList] = useState<AccessDeviceModel[]>(defaultValues);

  const deviceListTableData = deviceList.map((device) => {
    const selectedIdList = selectedDeviceList.map((device) => device.id);

    return {
      checked: selectedIdList.includes(device.id),
      ...device,
    };
  });

  const searchFormRef = useRef<HTMLFormElement>(null);
  const { handleSubmit, control } = useForm<{ searchWord: string }>();
  const { executeAsync: getDevice } = useApiOperation(getAccessDeviceListAsync);

  const onSubmit = ({ searchWord }: { searchWord: string }) => setSearchedWord(searchWord);
  const goPage = (nextPage: number) => setPage({ ...page, current: nextPage });

  const fetchDeviceList = useCallback(async (searchWord: string, partnerId?: string) => {
    const result = await getDevice({
      page: page.current,
      size: 20,
      searchValue: searchWord,
      ...(partnerId ? { partnerId } : {}),
    });

    setDeviceList(result.data.data.content);

    setPage({
      ...page,
      total: result.data.meta.pageMeta?.totalPages || 0,
    });
  }, []);

  const handleCheckboxClick = (checkbox: HTMLDivElement) => {
    const deviceId = Number(checkbox.dataset.deviceId);
    const checked = Boolean(checkbox.dataset.checked === "true");

    if (!checked) {
      const selectedDevice = deviceList.find((device) => device.id === deviceId);
      if (multiSelect) {
        setSelectedDeviceList([...selectedDeviceList, selectedDevice!]);
      } else {
        setSelectedDeviceList([selectedDevice!]);
      }
    } else {
      const filteredDeviceList = selectedDeviceList.filter((device) => device.id !== deviceId);
      setSelectedDeviceList(filteredDeviceList);
    }
  };

  const handleModalContentsClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLDivElement;

    const tr = target.closest(".base-table__tr");
    const td = tr?.children[0];
    const checkbox = td?.children[0] as HTMLDivElement;

    if (checkbox) handleCheckboxClick(checkbox);
  };
  const handleSearchClick = () => {
    searchFormRef.current?.dispatchEvent(
      new Event("submit", {
        bubbles: true,
      }),
    );
  };
  const handleCancelButtonClick = () => onCanceled();
  const handleAddSelectedDeviceButtonClick = () => onAdded(selectedDeviceList);

  useEffect(() => {
    if (partnerId) {
      fetchDeviceList(searchedWord, partnerId);
    } else {
      fetchDeviceList(searchedWord);
    }
  }, [searchedWord, partnerId, fetchDeviceList]);

  return (
    <BaseModal isOpen={true} className="dialog-modal">
      <div>
        <div className="modal-title">
          <div className="left-area">
            <span>장치 선택</span>
          </div>
          <form className="right-area" onSubmit={handleSubmit(onSubmit)} ref={searchFormRef}>
            <Controller
              name="searchWord"
              control={control}
              render={({ field }) => (
                <BaseInput
                  placeholder="검색어를 입력하세요"
                  value={field.value}
                  onChange={field.onChange}
                  onSearchClick={handleSearchClick}
                />
              )}
            ></Controller>
          </form>
        </div>
        <div className="base-modal__contents" onClick={handleModalContentsClick}>
          <ViewDataTable columns={DEVICE_TABLE_COLUMNS} data={deviceListTableData} />
          <BasePagination pageIndex={page.current} totalPages={page.total} goPage={goPage} />
        </div>
        <div className="base-modal__btn-wrap">
          <BaseButton
            title={"취소"}
            className="color-white no-outline text-primary3 mr10"
            onClick={handleCancelButtonClick}
          />
          <BaseButton title={"선택 추가"} onClick={handleAddSelectedDeviceButtonClick} />
        </div>
      </div>
    </BaseModal>
  );
}
export default DeviceSelectModal;
