import { ReactChild } from "react";
import { FileData } from "./file/file-types";
import { UnionServiceType } from "./adminMemo/adminmemo-types";
interface ErrorCodeMessage {
  errorCode: string;
  message: string;
  errorData?: any;
}

interface HttpStatusError {
  statusCode: string;
  message: string;
}

const errorStatus: HttpStatusError[] = [
  {
    statusCode: "400",
    message: "요청 정보가 API 명세에 부합되지 않음",
  },
  {
    statusCode: "401",
    message: "인증 실패",
  },
  {
    statusCode: "403",
    message: "인증은 성공 했으나 권한이 없음",
  },
  {
    statusCode: "404",
    message: "URI 경로에 해당하는 리소스가 없음",
  },
  {
    statusCode: "409",
    message: "충돌",
  },
  {
    statusCode: "500",
    message: "비즈니스 로직 에러",
  },
];

const building_product_errors: ErrorCodeMessage[] = [
  {
    errorCode: "ePR0511",
    message: "필수 파라미터 누락",
  },
  {
    errorCode: "ePR0512",
    message: "파싱 에러",
  },
  {
    errorCode: "ePR0513",
    message: "이미 등록된 데이터",
  },
  {
    errorCode: "ePR0514",
    message: "요청한 데이터가 없음",
  },
  {
    errorCode: "ePR0515",
    message: "요청한 파일이 없음",
  },
  {
    errorCode: "ePR0516",
    message: "데이터가 유효하지 않음",
  },
  {
    errorCode: "ePR0517",
    message: "삭제 불가 데이터",
  },
  {
    errorCode: "ePR0518",
    message: "요청 프로세스 중 전체 또는 일부가 완료되지 않은 경우",
  },
  {
    errorCode: "ePR0551",
    message: "주소명이 동일한 건물이 존재함",
  },
  {
    errorCode: "ePR0552",
    message: "빌딩명이 동일한 건물이 존재함",
  },
  {
    errorCode: "ePR0553",
    message: "동일한 로케이션 코드 존재함",
  },
  {
    errorCode: "ePR0554",
    message: "예약 가능 시간이 아님",
  },
  {
    errorCode: "ePR0555",
    message:
      "월 잔여시간이 0인 경우, 월 예약 최대 시간이 잔여시간보다 작은 경우, 잔여시간이 요청시간보다 작은경우",
  },
  {
    errorCode: "ePR0556",
    message:
      "일 잔여시간이 0인 경우, 일 예약 최대 시간이 잔여시간보다 작은 경우, 잔여시간이 요청시간보다 작은경우",
  },
  {
    errorCode: "ePR0557",
    message: "계약이 종료됨",
  },
  {
    errorCode: "ePR0517",
    message:
      "사용중인 공용공간은 삭제할 수 없습니다. 해당 공용공간을 하위 단계에서 먼저 삭제해 주세요.",
  },
];

const contract_bill_user_errors: ErrorCodeMessage[] = [
  {
    errorCode: "eCT101",
    message: "정보를 찾을 수 없거나 삭제되었습니다.",
  },
  {
    errorCode: "eCT102",
    message: "필수 매개변수가 전달되지 않았습니다.",
  },
  {
    errorCode: "eCT103",
    message: "유효한 값이 아닙니다. ",
  },
  {
    errorCode: "eCT104",
    message: "유효하지 않은 전화번호 입니다.",
  },
  {
    errorCode: "eCT105",
    message: "필수 정보가 전달되지 않았습니다.",
  },
  {
    errorCode: "eCT106",
    message: "등록가능 인원을 초과하였습니다.",
  },
  {
    errorCode: "eCT107",
    message: "등록가능 계좌를 초과하였습니다.",
  },
  {
    errorCode: "eCT108",
    message: "삭제할 수 없는 데이터입니다.",
  },
  {
    errorCode: "eCT109",
    message: "변경할 수 없는 데이터입니다.",
  },
  {
    errorCode: "eCT110",
    message: "다른 파트너에 사용 중인 계좌는 등록할 수 없습니다.",
  },
  {
    errorCode: "eCT111",
    message: "공간상품에 적용된 프로바이더입니다. 삭제시 공간상품(상품정보)에서 제외해 주세요.",
  },
  {
    errorCode: "eCT112",
    message: "건물에 적용된 관리처입니다. 삭제 필요시 건물(관리처)에서 제외해 주세요.",
  },
  {
    errorCode: "eCT113",
    message: "같은 공급구분 및 같은 계좌용도에 사용 중인 계좌는 등록할 수 없습니다.",
  },
  {
    errorCode: "eCT150",
    message: "배치 전용 api 입니다.",
  },
  {
    errorCode: "eCT151",
    message: "로그인한 파트너가 접근할 수 없는 데이터입니다.",
  },

  {
    errorCode: "eCT201",
    message: "계약 계획 또는 계약 수락 시 스냅샷을 저장할 수 있습니다.",
  },
  {
    errorCode: "eCT202",
    message: "계약 스냅샷이 존재하지 않습니다. ",
  },
  {
    errorCode: "eCT203",
    message: "계약 스냅샷이 프리징 되어 있습니다.",
  },
  {
    errorCode: "eCT204",
    message:
      "최대 이용자 수를 초과하였습니다. 이용자를 추가하시려면 등록된 이용자 삭제 후 다시 시도해 주세요.",
  },
  {
    errorCode: "eCT205",
    message: "건물에서 관리처를 등록해 주세요.",
  },
  {
    errorCode: "eCT206",
    message: "공간상품에 관리처 프로바이더가 등록되어 있습니다.",
  },
  {
    errorCode: "eCT207",
    message: "건물에 임대료 프로바이더가 등록되어 있습니다.",
  },
  {
    errorCode: "eCT208",
    message: "건물에 프로바이더가 등록되지 않았거나 삭제되었습니다.",
  },
  {
    errorCode: "eCT301",
    message: "이미 청구된 계약입니다. ",
  },
  {
    errorCode: "eCT302",
    message: "계약 별 상품 공용시설은 중복으로 저장할 수 없습니다.",
  },
  {
    errorCode: "eCT303",
    message: "이미 초대되어 있거나, 이용중인 회원입니다.",
  },
  {
    errorCode: "eCT304",
    message: "정기결제일은 1일부터 28일 사이로 설정 가능합니다.",
  },
  {
    errorCode: "eCT305",
    message: "변경 가능한 청구일자가 압니다.",
  },
  {
    errorCode: "eCT306",
    message: "결제 처리 완료된 청구상태는 변경 할 수 없습니다.",
  },
  {
    errorCode: "eCT307",
    message: "자동빌링 실행이 비활성화 되었습니다. ",
  },
  {
    errorCode: "eCT308",
    message: "세금계산서가 이미 발행되었습니다. ",
  },
  {
    errorCode: "eCT309",
    message: "결제완료 된 연체청구서가 있는 경우, 거래일시를 변경할 수 없습니다.",
  },
  {
    errorCode: "eCT310",
    message: "부가세 금액이 0원 입니다.",
  },
  {
    errorCode: "eCT311",
    message: "한컴 오메가연동이 사용불가 상태입니다.",
  },
  {
    errorCode: "eCT312",
    message: "결제를 위한 billkey가 등록되지 않았습니다. ",
  },
  {
    errorCode: "eCT313",
    message: "해당 현금영수증은 이미 발행되었습니다. ",
  },
  {
    errorCode: "eCT314",
    message: "해당 현금영수증은 이미 취소되었습니다.",
  },
  {
    errorCode: "eCT315",
    message: "청구무료인 경우, 현금영수증을 발행 할 수 없습니다. ",
  },
  {
    errorCode: "eCT316",
    message: "결제타입은 미납된 청구서가 없을 때만 변경할 수 있습니다. ",
  },
  {
    errorCode: "eCT317",
    message: "취소할수 없는 현금영수증입니다.",
  },
  {
    errorCode: "eCT318",
    message: "해당 현금영수증은 이미 등록되어 있습니다.",
  },
  {
    errorCode: "eCT319",
    message: "발행취소가 불가능한 세금계산서 입니다.",
  },
  {
    errorCode: "eCT320",
    message: "다른 파트너에 이미 등록되어 있는 계정으로 중복해서 등록할 수 없습니다.",
  },
  {
    errorCode: "eCT400",
    message: "페이레터 결제요청이 실패하였습니다.",
  },
  {
    errorCode: "eCT401",
    message: "결제할 수 없는 결제 정보입니다.",
  },
  {
    errorCode: "eCT402",
    message: "결제취소는 신용카드, 인터넷뱅킹만 가능합니다.",
  },
  {
    errorCode: "eCT403",
    message: "계약 정보에 계약ID이(가) 존재하지 않습니다.",
  },
  {
    errorCode: "eCT405",
    message: "계약 ID로 자동 결제 성공 데이터를 찾을 수 없습니다.",
  },
  {
    errorCode: "eCT406",
    message: "이미 지불되었거나 무료 건입니다",
  },
  {
    errorCode: "eCT407",
    message: "페이레터 리턴코드가 0이 아닙니다.",
  },
  {
    errorCode: "eCT408",
    message: "팝빌 api 호출 결과가 오류입니다.",
  },
  {
    errorCode: "eCT409",
    message: "카드결제형은 부분수납을 허용하지 않습니다.",
  },
  {
    errorCode: "eCT411",
    message: "팝밀 api key가 검증되지 않았습니다.",
  },
  {
    errorCode: "eCT412",
    message: "연체청구 결제부터 취소해야 합니다.",
  },
  {
    errorCode: "eCT413",
    message: "입금액을 초과하여 수납하실 수 없습니다.",
  },
  {
    errorCode: "eCT414",
    message: "초과 수납된 금액에 대해서만 결제취소가 가능합니다.",
  },
  {
    errorCode: "eCT415",
    message: "이미 취소된 결제입니다.",
  },
  {
    errorCode: "eCT416",
    message: "프로바이더 정보의 api가 검증되지 않았습니다.",
  },
  {
    errorCode: "eCT501",
    message: "정보를 찾을 수 없거나 삭제되었습니다.",
  },
  {
    errorCode: "eCT502",
    message: "필수 매개변수가 전달되지 않았습니다.",
  },
  {
    errorCode: "eCT503",
    message: "유효한 값이 아닙니다.",
  },
  {
    errorCode: "eCT504",
    message: "공간상품 타입이 SERVICE COURT가 아닙니다.",
  },
  {
    errorCode: "eCT505",
    message: "방문시간이 종료되었습니다.",
  },
  {
    errorCode: "eCT506",
    message: "방문 초대가 취소되었습니다.",
  },
  {
    errorCode: "eCT511",
    message: "데이터 형식에 문제가 있습니다.",
  },
  {
    errorCode: "eCT512",
    message: "시작일은 종료일보다 앞서야 합니다.",
  },
  {
    errorCode: "eCT601",
    message: "현재 이용자 초대를 할 수 없는 상태입니다. 계약진행 상태를 확인해 주세요. ",
  },
  {
    errorCode: "eCT602",
    message: "유효하지 않은 전화번호 입니다.",
  },
  {
    errorCode: "eCT603",
    message: "공용시설이 사용불가 상태입니다.",
  },
  {
    errorCode: "eCT604",
    message: "유효하지 않은 회원번호 입니다. ",
  },
  {
    errorCode: "eCT605",
    message: "등록하신 이메일은 캘린더시스템에서 찾을수 없습니다. 확인해 주세요.",
  },
  {
    errorCode: "eCT606",
    message: "현재 한컴 오메가의 연동을 사용할 수 없습니다. 계약진행 상태를 확인해 주세요.",
  },
  {
    errorCode: "eCT607",
    message: "이용자가 계약자일 경우 삭제 불가능 합니다.",
  },
  {
    errorCode: "eCT608",
    message: "로그인 하지 않았거나 유효기간이 만료되었습니다.",
  },
  {
    errorCode: "eCT609",
    message: "유효한 권한이 아닙니다.",
  },
  {
    errorCode: "eCT610",
    message: "요청한 파트너 아이디와 공간상품 등록 파트너 아이디가 일치하지 않습니다. ",
  },
  {
    errorCode: "eCT611",
    message: "이용자 정보를 찾을 수 없거나 삭제 되었습니다.",
  },
  {
    errorCode: "eCT612",
    message: "건물에 관리처가 등록되지 않았습니다.",
  },
  {
    errorCode: "eCT613",
    message: "관리처에 프로바이더가 등록되지 않았습니다. ",
  },
  {
    errorCode: "eCT800",
    message: "페이레터 결제요청이 실패하였습니다.",
  },
  {
    errorCode: "eCT801",
    message: "자동결제 배치가 실행중입니다. ",
  },
];
/**
 * api meta errorCode
 * https://pnpt.atlassian.net/wiki/spaces/MVPD/pages/51970243/BFF+Product+Response?focusedCommentId=55771313#meta
 */
type ErrorCode =
  | "ePR0511"
  | "ePR0512"
  | "ePR0513"
  | "ePR0514"
  | "ePR0515"
  | "ePR0516"
  | "ePR0551"
  | "ePR0552"
  | "ePR0553"
  | string;

interface ApiResponse<T> {
  data: T;
  meta: ApiResponseMeta;
}

interface ApiResponseMeta {
  errorCode?: ErrorCode;
  errorMessage?: string;
  errorData?: any;
  pageMeta?: PageMeta;
}
interface PageMeta {
  pageRequest: {
    page?: number;
    size?: number;
    sort?: any;
  };
  totalPages?: number;
  totalElements?: number;
}
interface Select {
  label: string;
  value: string;
}

interface Sort {
  orders?: Array<Order>;
}

interface Order {
  property: string;
  direction: string;
  ignoreCase?: boolean;
  nullHandling?: "NATIVE" | "NULLS_FIRST" | "NULLS_LAST";
}

// 공간상품 공통객체 (Space Product)

interface Address {
  id?: string;
  zonecode?: string;
  address?: string; // 기본주소 필수
  addressDetail?: string; // 상세주소 필수(사용자 직접 입력. daum 주소에서 얻어온 정보가 아님)
  addressEnglish?: string;
  addressType?: string;
  userSelectedType?: string;
  noSelected?: string;
  userLanguageType?: string;
  roadAddress?: string;
  roadAddressEnglish?: string;
  jibunAddress?: string;
  jibunAddressEnglish?: string;
  autoRoadAddress?: string;
  autoRoadAddressEnglish?: string;
  autoJibunAddress?: string;
  autoJibunAddressEnglish?: string;
  buildingCode?: string;
  buildingName?: string;
  apartment?: string;
  sido?: string;
  sidoEnglish?: string;
  sigungu?: string;
  sigunguEnglish?: string;
  sigunguCode?: string;
  roadnameCode?: string;
  bcode?: string;
  roadname?: string;
  roadnameEnglish?: string;
  bname?: string;
  bnameEnglish?: string;
  bname1?: string;
  bname1English?: string;
  bname2?: string;
  bname2English?: string;
  hname?: string;
}

export type MediaServiceType =
  | "MEDIASERVICE_UNRECOGNIZED" // 정의되지 않은 타입
  | "MEDIA_BUILDING" // 건물 외관
  | "MEDIA_BUILDING_INSIDE" // 건물 내부
  | "MEDIA_BUILDING_FLOOR" // 건물 층
  | "MEDIA_BUILDING_ROOM" // 건물 호실
  | "MEDIA_BUILDING_FLOOR_ROOM" //  건물 층/호실
  | "MEDIA_BUILDING_COMMONFACILITY" // 건물 공용시설
  | "MEDIA_BUILDING_MEETING_ROOM" // 회의실
  | "MEDIA_BUILDING_DESK" // 좌석
  | "MEDIA_BUILDING_REFRESH_ROOM" // 리프레쉬공간
  | "MEDIA_BUILDING_ETC" // 건물 기타
  | "MEDIA_PRODUCT_FLOOR_PLAN" //공간상품 평면도
  | "MEDIA_PRODUCT_OFFICE" //상품_사무공간 ( 공간상품 대표 카테고리 )
  | "MEDIA_PRODUCT_MEETING_ROOM" //상품_회의실
  | "MEDIA_PRODUCT_DESK" //상품_데스크
  | "MEDIA_PRODUCT_REFRESH_ROOM" //상품_리프레쉬룸
  | "MEDIA_PRODUCT_TOILET" //상품_화장실
  | "MEDIA_PRODUCT_PARKING_LOT" //상품_주차장
  | "MEDIA_PRODUCT_FACILITY" //상품_공용시설
  | "MEDIA_PRODUCT_ETC" //상품_기타
  | "MEDIA_PRODUCT_BUILDING_OUTSIDE" //상품>건물>외관
  | "MEDIA_PRODUCT_BUILDING_INSIDE" //상품>건물>내부>호실별
  | "MEDIA_PRODUCT_BUILDING_FACILITY" //상품>건물>시설
  | "MEDIA_PRODUCT_BUILDING_ROOM" //상품>건물>층/호실
  | "MEDIA_PRODUCT_BUILDING_ETC" //상품>건물>기타
  | "MEDIA_WORK_ORDER_SHEET" // 워크오더>워크시트>업무내용
  | "MEDIA_WORK_ORDER_SHEET_COMPLETED_REPORT" //워크오더>워크시트>처리내용
  | "MEDIA_WORK_ORDER_SHEET_COST" //워크오더>워크시트>처리비용
  | "SERVICE_QUESTION"; //CS

export const CmdType = {
  C: "C", // 등록
  U: "U", // 수정
  D: "D", // 삭제
} as const;

export type UnionCmdType = typeof CmdType[keyof typeof CmdType];

interface MediaFile extends FileData {
  id?: number; // id. 수정시 필요
  serviceId?: string; // 서비스 ID
  partnerId?: string; // 센트럴에서 partnerId 없이 등록시 컨트롤룸에서 파일 호출 불가
  mediaServiceType?: MediaServiceType;
  // serviceType: any;
  category1?: string;
  category2?: string;
  description?: string; //이미지 설명
  selfDescription?: string; // 직적입력 설명 (명세서에는 X)
  isPrimary?: boolean; // 대표여부
  orderNums?: number; // 정렬순서
  url?: string;
  // cmdType?: UnionCmdType;
  cmdType?: string;
  isRemoved?: boolean;
  [key: string]: any;
}

interface PakringLot {
  id: number;
  buildingId: number;
  parkingLotType: string;
  parkingSpaceNums: number;
  description: string;
  isDeleted: boolean;
}

interface MeetingRoom {
  id: number;
  buildingId: number;
  meetingRoomName: string;
  floorNums: number;
  maxPeopleNums: number;
  equipment: string;
  isDeleted: boolean;
  fileList: Array<MediaFile>;
}

type MetaGroupType =
  | "METAGROUP_UNRECOGNIZED" // 정의되지 않은 타입
  | "PRODUCT_DIRECTION" // 건물방향
  | "PRODUCT_RENT" // 사용료
  | "PRODUCT_RENT_DISCOUNT" // 사용료 할인
  | "PRODUCT_MAINTENANCE_FEE" // 관리비 내역
  | "PRODUCT_AIR_CONDITIONER" // 공조기
  | "PRODUCT_OPTIONAL_FACILITY" // 추가시설/서비스
  | "PRODUCT_TOILET" // 공간상품 화장실
  | "PRODUCT_FLOOR" // 공간상품 층
  | "PRODUCT_PRINTER" // 복합기
  | "PRODUCT_FREE_INTERNET" // 무료인터넷
  | "PRODUCT_COMMON_FACILITY" // 공간상품 공용공간
  | "PRODUCT_PARKING_LOT" // 공간상품 공용공간 주차장
  | "PRODUCT_DESK" // 공간상품 공용공간 좌석
  | "PRODUCT_MEETING_ROOM" // 공간상품 공용공간 회의실
  | "PRODUCT_REFRESH_ROOM" // 공간상품 공용공간 편의시설
  | "BUILDING_STRUCTURE" // 건물 건축물 용도
  | "BUILDING_TOILET" // 건물 화장실
  | "BUILDING_COMMON_FACILITY"; // 건물 공용공간

interface MetaData {
  id?: number;
  metaGroup?: MetaGroupType;
  metaItem?: string;
  orderNums?: number;
  value1?: string;
  value2?: string;
  value3?: string;
}

export interface BuildingStructureMetaData {
  id?: number;
  metaGroup?: MetaGroupType;
  type?: string; // metaItem 건축물 용도 코드
  code?: string; //value1
  description?: string; //value2
  orderNums?: number;
}

//화장실 metaData
export interface BuildingToiletMetaData {
  id?: number;
  metaGroup?: MetaGroupType;
  type?: string;
  orderNums?: number;
  floor?: string; //value1
  man?: string; //value2
  women?: string; //value3
}

export interface DirectionType {
  value: MetaData;
  label: string;
}

// modal 공통

export type Modal = {
  isOpen: boolean;
  children?: ReactChild;
  message?: string;
  type?: string;
  payload?: any;
  title?: string;
};

// 냉난방타입
export type HeatingType =
  | "OPTIONAL" // TODO
  | "HEATING_UNRECOGNIZED" // 정의되지 않은 타입
  | "CENTRAL_AIR_CONDITIONER" // 중앙냉난방
  | "INDIVIDUAL_AIR_CONDITIONER" // 개별냉난방
  | "DIRECT_HEATING_AIR_CONDITIONER"; // 직접입력

export type {
  ApiResponse,
  ApiResponseMeta,
  PageMeta,
  Sort,
  Order,
  MediaFile,
  PakringLot,
  MeetingRoom,
  Address,
  MetaGroupType,
  MetaData,
  Select,
  HttpStatusError,
  ErrorCodeMessage,
};

export { contract_bill_user_errors, errorStatus, building_product_errors };

export type Status =
  | "DRAFT" // 작성중
  | "ENABLED" // 공개
  | "DISABLED" // 비공개
  | "CANCELED" // 	관리자 등에 의해 사용중지(취소) 된 경우
  | "EXPIRED" // 기간만료
  | "LINK_ENABLED" // 링크공개
  | "RESERVATION_ENABLED" //예약완료
  | "RESERVATION_CANCELED" //관리자에 의해 취소된 경우
  | "RESERVATION_EXPIRED" //예약 취소 ( 정상 예약 )   시간 제한이 있는 경우 1시간 이내(~59분) 취소한 상태로 사용 시간에 포함됨
  | "RESERVATION_INCOMPLETED_FACILITY" //불완전 예약/ 이미 회의실이 예약되어 있어서, 예약이 불가한 경우
  | "RESERVATION_INCOMPLETED_QUOTA_DAYLY" //불완전 예약 / 일 사용 시간 제한으로 예약하지 못한 경우
  | "RESERVATION_INCOMPLETED_QUOTA_MONTHLY" //	불완전 예약 / 월 사용 시간 제한으로 예약하지 못한 경우
  | "RESERVATION_INVITEE_DISABLED" //회신안함
  | "RESERVATION_INVITEE_ACCEPT" //참여
  | "RESERVATION_INVITEE_REFUSE" // 참여거절
  | "RESERVATION_INVITEE_PENDENCY"
  | "WORK_ORDER_OPEN" //워크오더 접수
  | "WORK_ORDER_PROGRESS" //워크오더 진행
  | "WORK_ORDER_COMPLETED" // 워크오더 완료
  | "WORK_ORDER_CLOSED" // 워크오더 종료
  | "WORK_ORDER_WAITING" // 워크오더 대기  - 담당자가 워크오더를 할당받은 것을 인지하여 확인하고 업무 처리를 시작하기 전 상태
  | "WORK_ORDER_CANCELED"; // 워크오더 삭제

export type SearchType =
  | "ALL" // 전차
  | "PRODUCT_NAME" // 상품명 검색
  | "ADDRESS" // 주소내 문자열 검색
  | "BUILDING_NAME" // 건물명 검색
  | "CREATED_BY" // 등록자 검색
  | "LOCATION_CODE" // 로케이션 코드
  | "FACILITY_NAME" // 공용시설 이름
  | "RESERVATION_ID" // 예약 아이디
  | "RESERVATION_GROUP_ID" // 반복예약 아이디
  | "SUMMARY" //제목
  | "NAME" //이름
  | "GROUP_ID" // 그룹ID
  | "ID" //ID
  | "REPORTER_NAME" //SUBJECT
  | "OFFICER_ID" // 담당자 ID
  | "OFFICER_NAME" // 담당자 이름
  | "SUBJECT" // 제목
  | "NUMBER"; //워크시트번호

export interface CommonDateNUser {
  createdDate?: string;
  createdBy?: string;
  modifiedDate?: string;
  modifiedBy?: string;
}

export interface MediaGroup {
  mediaList: MediaFile[];
  cmdType: UnionCmdType;
}

export interface MediaContentsList {
  contentsList?: Array<{
    serviceId: number;
    // mediaServiceType: string;
    serviceType: UnionServiceType;
  }>;
}
