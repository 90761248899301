export const popbillTabs = [
  { value: "basicInfo", label: "기본정보" },
  { value: "accountInfo", label: "계좌정보" },
];

export const popbillAccountError = {
  "300": "금융 기관에 등록되지 않은 계좌번호입니다.",
  "301": "계좌번호가 등록된 정보(생년월일 또는 사업자번호)와 일치하지 않습니다.",
  "400": "금융 기관에서 거래가 제한된 계좌번호입니다.",
  "801": "금융 기관 시스템 점검 중입니다.",
  "898": "금융 기관에서 결과를 제공하지 않았습니다.",
  "899": "알 수 없는 오류가 발생하였습니다.",
  "201": "금융 기관 시스템 정기 점검 시간입니다. (매일 23:30~00:30)",
  "202": "유효하지 않은 계좌번호입니다.",
  "204": "외환 계좌는 지원되지 않습니다.",
  "206": "지원되지 않는 금융기관입니다.",
  "998": "금융 기관에서 시스템에 장애가 발생했습니다.",
};

export const PopbillSearchType = {
  ALL: "ALL",
  BIZ_REG_NO: "BIZ_REG_NO",
  BIZ_NAME: "BIZ_NAME",
} as const;

export type UnionPopbillSearchType = typeof PopbillSearchType[keyof typeof PopbillSearchType];

export const PopbillSearchOptions = [
  { label: "전체", value: PopbillSearchType.ALL },
  { label: "법인명/상호", value: PopbillSearchType.BIZ_NAME },
  { label: "사업자등록번호", value: PopbillSearchType.BIZ_REG_NO },
];
