import qs from "qs";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContentsTitle } from "src/components";
import { PagePath } from "src/pages/product/details";

import BasicInfo from "./BasicInfoForm";
// import Log from "../detail/AccessGroupLog";

const AccessDeviceForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const tabs = [
    { value: "basicInfo", label: "기본정보" },
    // { value: "log", label: "로그" },
  ];

  const queryParams = useMemo(
    () =>
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
        allowDots: true,
      }),
    [location],
  );

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return tabs.find((tab) => tab.value === queryParams?.tab);
    }
    return tabs[0];
  }, [queryParams]);

  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };
      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }
      newQueryParams.tab = tab.value;

      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location, queryParams],
  );

  return (
    <div>
      <ContentsTitle title="출입장치 관리" tabs={tabs} activeTab={activeTab} clickTab={clickTab} />
      <div className="contents-container__scroll">
        {activeTab?.value === "basicInfo" && <BasicInfo />}
      </div>
    </div>
  );
};

export default AccessDeviceForm;
