import moment from "moment";
import { Link } from "react-router-dom";
import { Cell } from "react-table";
import { ReservationListModel } from "src/api/reservation/reservation-types";
import { BaseTooltip } from "src/components/BaseTooltip";
import { PagePath } from "src/pages/product/details";
import { PhoneFomatter, YmdFormat, formatPhoneNumber } from "src/utils/common-util";
import {
  ExternalServiceType,
  getStatusText,
  getStatusTextAndColor,
  ReservationState,
} from "../../reservation-types";

/* 
  공용공간 예약 목록 columns
*/

const ReservationColumns = [
  {
    Header: "id",
    accessor: "id",
    sticky: "left",
    width: 80,
    Cell: (props: Cell<ReservationListModel>) => {
      const detailPath = `${PagePath.reservation.detail.replace(
        ":id",
        `${props.row.original.id}`,
      )}`;
      return (
        <div>
          <Link className="text-hilight" to={`${detailPath}`}>
            {props.value}
          </Link>
        </div>
      );
    },
  },
  {
    Header: "그룹Id",
    accessor: "parentId",
    sticky: "left",
    width: 80,
    Cell: (props: Cell<ReservationListModel>) => {
      const detailPath = `${PagePath.reservation.detail.replace(
        ":id",
        `${props.row.original.id}`,
      )}`;
      return (
        <div>
          {props.value && props.value !== "0" ? (
            <Link className="text-hilight" to={`${detailPath}`}>
              {props.value}
            </Link>
          ) : (
            <span>-</span>
          )}
        </div>
      );
    },
  },
  {
    Header: "예약 상태",
    accessor: "status.code2",
    sticky: "left",

    width: 100,
    Cell: ({ row }: Cell<ReservationListModel>) => {
      const code = row.original.status.code;
      const startDate = row.original.start;
      const endDate = row.original.end;
      const textAndColor = getStatusTextAndColor(code, startDate, endDate);
      return <div className={`${textAndColor.color}`}>{textAndColor.label}</div>;
    },
  },
  {
    Header: "예약 유형",
    accessor: "rvType",
    width: 100,
    Cell: ({ row }: Cell<ReservationListModel>) => {
      const start = row.original.start;
      const end = row.original.end;
      const diffInHours = moment(end).diff(moment(start), "hours", true);

      const isOver24h = diffInHours >= 24;

      // 24시간 이상이면 true, 미만이면 false 반환

      return <div>{isOver24h ? "전용" : "공용"}</div>;
    },
  },
  {
    Header: "예약 경로",
    accessor: "inboundChannel",
    width: 160,
    Cell: (props: Cell<ReservationListModel>) => {
      let externalServiceType = "";

      if (props.value === ExternalServiceType.TAAP) {
        externalServiceType = "Taap";
      } else if (props.value === ExternalServiceType.GC) {
        externalServiceType = "Google Calendar";
      } else if (props.value === ExternalServiceType.OC) {
        externalServiceType = "Outlook Calendar";
      } else if (props.value === ExternalServiceType.CTRL_ROOM) {
        externalServiceType = "Ctrl.room";
      } else if (props.value === ExternalServiceType.TAAP_SPACE) {
        externalServiceType = "TaapSPACE";
      } else {
        externalServiceType = "알 수 없음";
      }

      return (
        <div>
          <span>{externalServiceType ? externalServiceType : "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "신청번호",
    accessor: "contract.applyNumber",
    width: 120,
    Cell: (props: Cell<ReservationListModel>) => {
      const detailPath = `${PagePath.contract.detail.replace(
        ":id",
        `${props.row.original.contract.id}`,
      )}`;
      return (
        <div>
          {props.value ? (
            <Link target={"_blank"} className="text-hilight" to={detailPath}>
              {props.value}
            </Link>
          ) : (
            "-"
          )}
        </div>
      );
    },
  },
  {
    Header: "건물 명",
    accessor: "facility.building.name",
    width: 180,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="w-100 text-left">
          <span>{value}</span>
        </div>
      );
    },
  },
  {
    Header: "예약명",
    accessor: "summary",
    width: 150,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value || "-"} isSingleLine />
        </div>
      );
    },
  },
  {
    Header: "위치",
    accessor: "facility.floor",
    width: 120,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      const floorText = value > 0 ? "지상" : "지하";
      return (
        <div>
          <span>
            {floorText} {Math.abs(value)}층
          </span>
        </div>
      );
    },
  },
  {
    Header: "공간타입",
    accessor: "facility.type",
    width: 90,
    Cell: (props: Cell<ReservationListModel>) => {
      if (props.value === "MEETING_ROOM") {
        return <span>회의실</span>;
      } else if (props.value === "DESK") {
        return <span>좌석</span>;
      } else {
        return <span>편의시설</span>;
      }
    },
  },
  {
    Header: "공간 명",
    accessor: "facility.name",
    width: 180,
    Cell: ({ value }: Cell<any>) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value || ""} />
        </div>
      );
    },
  },
  {
    Header: "예약일",
    accessor: "start",
    width: 120,
    Cell: ({ value }: any) => {
      return (
        <div>
          <span>{value ? moment(value).format(YmdFormat.YYYY_MM_DD) : "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "예약 시간",
    accessor: "end",
    width: 120,
    Cell: (props: Cell<ReservationListModel>) => {
      const startDate = props.row.original.start;
      const endDate = props.row.original.end;
      return (
        <div>
          <span>{moment(startDate).format("HH:mm")}</span> ~
          <span>{moment(endDate).format("HH:mm")}</span>
        </div>
      );
    },
  },
  {
    Header: "회원번호",
    accessor: "organizer.memberNo",
    width: 120,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return (
        <div className="d-flex flex-column">
          <span>{value || "-"}</span>
        </div>
      );
    },
  },
  {
    Header: "휴대폰번호",
    accessor: "organizer.phoneNumber",
    width: 140,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return <div className="d-flex flex-column">{value ? formatPhoneNumber(value) : "-"}</div>;
    },
  },
  {
    Header: "이메일",
    accessor: "organizer.userEmail",
    width: 180,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      return <div className="d-flex flex-column w-100 text-left">{value || "-"}</div>;
    },
  },
  {
    Header: "생성일",
    accessor: "createdDate",
    width: 120,
    Cell: (props: Cell<ReservationListModel>) => {
      const createdDate = props.row.original.createdDate;
      return <BaseTooltip contents={createdDate} type="date" />;
    },
  },
  {
    Header: "점유 상태",
    accessor: "status.code",
    width: 90,
    Cell: ({ value }: Cell<ReservationListModel>) => {
      const statueText = getStatusText(value);
      return (
        <div
          className={`${
            (value === ReservationState.ACCEPTED && "chip green") ||
            (value === ReservationState.CANCELLED && "chip red") ||
            (value === ReservationState.ACKNOWLEDGED && "chip green") ||
            (value === ReservationState.INCOMPLETE && "chip red")
          }`}
        >
          {statueText}
        </div>
      );
    },
  },
  {
    Header: "파트너",
    accessor: "partnerId",
    width: 100,
    Cell: (props: Cell<ReservationListModel>) => {
      return (
        <>
          <a
            className="text-hilight "
            href={`${PagePath.partner.detail.replace(":id", props.value)}`}
            target="_blank"
            rel="noreferrer"
          >
            {props.value}
          </a>
        </>
      );
    },
  },
];

export default ReservationColumns;
