import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { addPopbillBizAsync, getPopbillDetailAsync } from "src/api/popbill/popbill-api";
import { PopbillBizAddRequest, PopbillBizDetailModel } from "src/api/popbill/popbill-types";
import { Modal } from "src/api/public-types";
import { BaseModal, ContentsTitle } from "src/components";
import { PagePath } from "src/pages/product/details";
import { popbillTabs } from "../popbill-constant";
import BasicInfoForm from "./BasicInfoForm";
import PopbillAccountForm from "./PopbillAccountForm";

const defaultValues = {
  bizName: "",
  bizRegNo: "",
  popbillAcctList: [
    {
      bankCode: "",
      accountHolder: "",
      accountNumber: "",
    },
  ],
};

const PopbillForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [modal, setModal] = useState<Modal>({
    isOpen: false,
  });

  const [confirmModal, setConfirmModal] = useState<Modal>({ isOpen: false });
  const [alertModal, setAlertModal] = useState<Modal>({ isOpen: false });

  const queryParams = useMemo(() => {
    return qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
  }, [location.search]);

  const pbBizId = useMemo(() => queryParams.id, [queryParams]);

  // 현재 활성화 되어야하는 tab
  const activeTab = useMemo(() => {
    if (queryParams?.tab) {
      return popbillTabs.find((tab) => tab.value === queryParams?.tab);
    }
    return popbillTabs[0];
  }, [queryParams]);

  // 탭 클릭시 callback 함수
  const clickTab = useCallback(
    (tab: { value: string; label: string }) => {
      const newQueryParams = { ...queryParams };

      if (tab.value !== "basicInfo" && !queryParams.id) {
        setModal({
          isOpen: true,
          message: "기본정보가 저장되지 않았습니다.",
        });
        return;
      }

      if (newQueryParams?.tab) {
        delete newQueryParams.tab;
      }

      newQueryParams.tab = tab.value;

      // query parameter 에 tab 추가
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [location, queryParams],
  );

  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<PopbillBizAddRequest>({
    defaultValues,
  });

  const { executeAsync: addPopbillBiz } = useApiOperation(addPopbillBizAsync);

  const { executeAsync: getPopbillDetail } = useApiOperation(getPopbillDetailAsync);

  const [popbill, setPopbill] = useState<PopbillBizDetailModel>();

  const getPopbill = async () => {
    const { data } = await getPopbillDetail({ pbBizId: String(pbBizId) });
    if (data.data.content) {
      const result = data.data.content;
      // result.popbillAcctList = result.popbillAcctList?.sort((a, b) => {
      //   return a.pbAccountId ? -1 : 1;
      // });

      setPopbill(result);

      setValue("pbBizId", result.pbBizId);
      setValue("bizName", result.bizName);
      setValue("bizRegNo", result.bizRegNo);
      setValue(
        "popbillAcctList",
        result.popbillAcctList && result.popbillAcctList.length > 0
          ? result.popbillAcctList
          : defaultValues.popbillAcctList,
      );
    }
  };

  useEffect(() => {
    if (pbBizId) {
      getPopbill();
    }
  }, [pbBizId]);

  const addPopbillBizMutation = async (passData: PopbillBizAddRequest) => {
    if (queryParams.tab && queryParams.tab === "accountInfo") {
      const { status } = await addPopbillBiz({
        pbBizId: Number(pbBizId),
        popbillAcctList: passData.popbillAcctList,
      });
      if (status >= 200 && status < 300) {
        navigate(PagePath.popbill.detail.replace(":id", String(pbBizId)) + "?tab=accountInfo");
      }
    } else {
      const request = { ...passData };
      delete request.popbillAcctList;
      const { data, status } = await addPopbillBiz(request);
      if (status >= 200 && status < 300) {
        navigate(PagePath.popbill.detail.replace(":id", String(data.data.pbBizId)));
      }
    }
  };

  // useEffect(
  //   () => {
  //     if (popbill) {
  //       setValue("pbBizId", popbill.pbBizId);
  //       setValue("bizName", popbill.bizName);
  //       setValue("bizRegNo", popbill.bizRegNo);
  //       console.log("popbill.popbillAcctList", popbill.popbillAcctList);
  //       setValue(
  //         "popbillAcctList",
  //         popbill.popbillAcctList && popbill.popbillAcctList.length > 0
  //           ? popbill.popbillAcctList
  //           : defaultValues.popbillAcctList,
  //       );
  //     }
  //   },
  //   [popbill],
  // );

  // 리액트 쿼리문 적용
  // //  팝빌 상세
  // const { data: popbill } = useQuery({
  //   queryKey: [
  //     "popbillDetail", //
  //     pbBizId,
  //   ],
  //   queryFn: () => getPopbillDetail({ pbBizId: String(pbBizId) }),
  //   select: (response) => {
  //     let result = response.data.data.content;
  //     result = {
  //       ...result,
  //       popbillAcctList: result.popbillAcctList?.sort((a, b) => {
  //         return a.pbAccountId ? -1 : 1;
  //       }),
  //     };
  //     console.log("result", result);
  //     return result;
  //   },
  //   enabled: !!pbBizId,
  //   staleTime: 0,
  // });

  // console.log("watch", watch());

  // // 팝빌 추가 / 업데이트
  // const { mutate: addPopbillBizMutation } = useMutation({
  //   mutationFn: async (data: PopbillBizAddRequest) => {
  //     if (queryParams.tab && queryParams.tab === "accountInfo") {
  //       return await addPopbillBiz({
  //         pbBizId: Number(pbBizId),
  //         popbillAcctList: data.popbillAcctList,
  //       });
  //     } else {
  //       const request = { ...data };
  //       delete request.popbillAcctList;
  //       return await addPopbillBiz(request);
  //     }
  //   },
  //   onSuccess: ({ data }) => {
  //     setConfirmModal({ isOpen: false });
  //     const result = data.data.pbBizId;
  //     if (queryParams.tab && queryParams.tab === "accountInfo") {
  //       navigate(PagePath.popbill.detail.replace(":id", String(result)) + "?tab=accountInfo");
  //     } else {
  //       navigate(PagePath.popbill.detail.replace(":id", String(result)));
  //     }
  //   },
  //   onError: () => {
  //     setConfirmModal({ isOpen: false });
  //   },
  // });

  // 유효성 검사
  // useEffect(() => {
  //   const currentTab = queryParams.tab;

  //   if (currentTab && currentTab === "accountInfo") {
  //     const currentAccounts = watch("popbillAcctList") || [];

  //     currentAccounts?.forEach((account, idx: number) => {
  //       register(`popbillAcctList.${idx}.bankCode`, {
  //         required: { value: true, message: "은행을 선택해주세요." },
  //       });

  //       register(`popbillAcctList.${idx}.accountNumber`, {
  //         required: { value: true, message: "계좌번호를 입력해주세요." },
  //         minLength: { value: 8, message: "8자 ~ 14자 숫자를 입력해주세요" },
  //         maxLength: { value: 14, message: "8자 ~ 14자 숫자를 입력해주세요" },
  //       });
  //       // register(`popbillAcctList.${idx}.accountHolder`, {
  //       //   validate: {
  //       //     required: (value) => {
  //       //       const bankCode = watch(`popbillAcctList.${idx}.bankCode`);
  //       //       const accountNumber = watch(`popbillAcctList.${idx}.accountNumber`);
  //       //       let result = true;
  //       //       let message = "";
  //       //       if (bankCode && accountNumber && !value) {
  //       //         result = false;
  //       //         message = "예금주 정보 확인은 필수사항입니다.";
  //       //         setAlertModal({ isOpen: true, message });
  //       //       }

  //       //       return result || message;
  //       //     },
  //       //   },
  //       // });
  //     });
  //   } else {
  //     register("bizName", {
  //       required: { value: true, message: "법인명/상호는 필수 입력 항목입니다" },
  //     });
  //     register("bizRegNo", {
  //       validate: {
  //         requried: (businessNumber) => {
  //           let result = true;
  //           let message = "";
  //           let validateBusinessNumber = checkValidateOrgNumber(String(businessNumber));

  //           if (!businessNumber) {
  //             result = false;
  //             message = "사업자 등록번호는 필수 입력 항목입니다.";
  //           } else if (!validateBusinessNumber) {
  //             result = false;
  //             message = "사업자 등록번호는 최소 10자리 이상이어야 합니다.";
  //           }

  //           return result || message;
  //         },
  //       },
  //     });
  //   }
  // }, [
  //   queryParams.tab,
  //   register, //
  //   watch,
  // ]);

  const onSubmit = (data: PopbillBizAddRequest) => {
    setConfirmModal({ isOpen: true, title: "저장하시겠습니까?", payload: data });
  };

  // validation 통과하지 못하고 error 발생시 실행
  const onError = (errors: any, e?: any) => {
    console.log("onError errors", errors);
    e.preventDefault();
    return false;
  };

  return (
    <section>
      <ContentsTitle
        title="팝빌 사업자/계좌"
        tabs={popbillTabs}
        activeTab={activeTab}
        clickTab={clickTab}
      />
      <div className="contents-container__scroll">
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {/* 기본정보 */}
          {activeTab?.value === "basicInfo" && (
            <BasicInfoForm control={control} popbill={popbill} register={register} />
          )}
          {/* 계좌정보 */}
          {activeTab?.value === "accountInfo" && (
            <PopbillAccountForm
              control={control}
              popbill={popbill}
              watch={watch}
              setValue={setValue}
              errors={errors}
              register={register}
            />
          )}
        </form>
      </div>

      {modal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle={"확인"}
          onClick={() => setModal({ ...modal, isOpen: false })}
        >
          <p>{modal.message}</p>
        </BaseModal>
      )}

      {confirmModal.isOpen && (
        <BaseModal
          isOpen={confirmModal.isOpen}
          title={confirmModal.title}
          onClick={() => {
            addPopbillBizMutation(getValues());
            setConfirmModal({ isOpen: false });
          }}
          onClose={() => {
            setConfirmModal({ isOpen: false });
          }}
          btnLeftTitle="취소"
          btnRightTitle="확인"
        />
      )}
      {alertModal.isOpen && (
        <BaseModal
          isOpen={true}
          btnRightTitle="확인"
          onClick={() => {
            setAlertModal({ isOpen: false });
          }}
        >
          <p className="pre-formatted">{alertModal.message}</p>
        </BaseModal>
      )}
    </section>
  );
};

export default PopbillForm;
