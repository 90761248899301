import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  deleteAccessGroupAsync,
  getAccessDeviceListAsync,
  getAccessGroupAsync,
} from "src/api/access/ac2-api";
import { AccessDeviceModel, AccessGroupModel } from "src/api/access/ac2-types";
import { useApiOperation } from "src/api/hooks";
import { getPartnerList } from "src/api/partner";
import { Modal } from "src/api/public-types";
import { BaseButton, BaseModal, ContentsIdSection } from "src/components";
import { useLoadingBarContext, useTitleOperation, useToastContext } from "src/pages/hooks";
import useErrorModalContext from "src/pages/hooks/error-modal-context";
import { PagePath } from "src/pages/product/details";
import { Partner } from "src/types/partner";
import { YmdFormat } from "src/utils";
import DevicesTable from "../components/DevicesTable";
import PartnersTable from "../components/PartnersTable";

/*
  출입그룹 관리 상세
*/
const BasicInfo = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const { openToast } = useToastContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { openErrorModal } = useErrorModalContext();
  const [accessGroup, setAccessGroup] = useState<AccessGroupModel>();
  const [accessGroupDevices, setAccessGroupDevices] = useState<Array<AccessDeviceModel>>();
  const [partners, setPartners] = useState<Partner[]>([]);
  const { executeAsync: getAccessGroup } = useApiOperation(getAccessGroupAsync);
  const { executeAsync: getPartners } = useApiOperation(getPartnerList);
  const { executeAsync: deleteAccessGroup } = useApiOperation(deleteAccessGroupAsync, {
    doNotErrorHandleModal: true,
  });
  const { executeAsync: getAccessDeviceList } = useApiOperation(getAccessDeviceListAsync);

  // breadCrumb 클릭시 callback 함수
  const clickBreadCrumb = useCallback(
    (crumb: { value: string; label: string }) => {
      if (crumb.value === "accessGroup") {
        navigate(PagePath.accessGroup.list);
      }
    },
    [navigate],
  );

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 출입그룹 삭제
  const handelEraseConfirm = async () => {
    if (id) {
      const { data } = await deleteAccessGroup({ id });

      setConfirmModal({ isOpen: false });

      if (data?.data?.accessGroup.id) {
        setConfirmModal({ isOpen: true, message: "삭제 되었습니다", type: "SUCCESS_DELETE" });
      } else if (data?.meta?.errorCode === "eAC101") {
        setAlertModal({
          isOpen: true,
          message: "삭제할 수 없습니다. 해당 출입그룹을 쓰고 있는 상품 또는 계약이 있습니다.",
        });
      } else {
        let errorMessage = data?.meta?.errorMessage;
        let errorCode = data?.meta?.errorCode;
        let errorData = data?.meta?.errorData;
        openErrorModal(errorMessage, errorCode, errorData);
      }
    }
  };

  useEffect(() => {
    if (id) {
      async function fetchAccessGroupInfo(id: string) {
        setLoadingBar(true);
        const { data } = await getAccessGroup({ id: Number(id) });
        const accessGroup = data?.data?.accessGroup;
        setAccessGroup(accessGroup);
        //장치 조회
        if (accessGroup.id) {
          const { data: accessGroupDeviceList } = await getAccessDeviceList({
            accessGroupId: Number(accessGroup.id),
          });
          if (accessGroupDeviceList) {
            setAccessGroupDevices(accessGroupDeviceList.data.content);
          }
        }
        setLoadingBar(false);
      }
      fetchAccessGroupInfo(id);
    }
  }, [getAccessGroup, setLoadingBar, id, getAccessDeviceList]);

  useEffect(() => {
    const fetchApi = async (partnersNumber: string) => {
      const data = await getPartners({ id: partnersNumber });
      const partnersResponseData = data?.data?.data?.content;
      setPartners(partnersResponseData);
    };
    if (accessGroup?.partnerIds && accessGroup?.partnerIds.length > 0) {
      fetchApi(accessGroup?.partnerIds.join());
    }
  }, [getPartners, accessGroup?.partnerIds]);
  useTitleOperation(accessGroup?.accessGroupName);

  return (
    <div>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents">
            <ContentsIdSection title="기본정보" id={id} />
            <article>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>출입그룹명</p>
                </div>
                <div className="contents-container__grid-contents">
                  <div className="flex-row flex-center-start ellipsis">
                    <span className="font14">{accessGroup?.accessGroupName || "-"}</span>
                  </div>
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>파트너</p>
                </div>
                <div className="contents-container__grid-contents">
                  <PartnersTable partners={partners || []} />
                </div>
              </section>
              <section className="contents-container__grid">
                <div className="contents-container__grid-index">
                  <p>장치</p>
                </div>
                <div className="contents-container__grid-contents">
                  <DevicesTable devices={accessGroupDevices || []} />
                </div>
              </section>
              <div className="d-flex">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>이용자 출입</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax300">
                      <span className="font14">
                        {accessGroup?.memberAccessYn === undefined
                          ? "-"
                          : accessGroup?.memberAccessYn
                          ? "출입가능"
                          : "출입불가"}
                      </span>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>방문자 초대</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax300">
                      <span className="font14">
                        {accessGroup?.visitorAccessYn === undefined
                          ? "-"
                          : accessGroup?.visitorAccessYn
                          ? "초대가능"
                          : "초대불가"}
                      </span>
                    </div>
                  </div>
                </section>
              </div>
              <div className="d-flex">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>Taap 노출</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax300">
                      <span className="font14">
                        {accessGroup?.visitorAccessGroupSelectableYn === undefined
                          ? "-"
                          : accessGroup?.visitorAccessGroupSelectableYn
                          ? "노출"
                          : "노출안함"}
                      </span>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>관리자 승인</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax300">
                      <span className="font14">
                        {accessGroup?.visitorInvitationApprovalYn === undefined
                          ? "-"
                          : accessGroup?.visitorInvitationApprovalYn
                          ? "승인필요"
                          : "자동승인"}
                      </span>
                    </div>
                  </div>
                </section>
              </div>
              <div className="d-flex">
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index">
                    <p>QR발급</p>
                  </div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax300">
                      <span className="font14">
                        {accessGroup?.visitorAccessType === undefined
                          ? "-"
                          : accessGroup?.visitorAccessType === "VISITOR_MUTABLE"
                          ? "발급"
                          : "미발급"}
                      </span>
                    </div>
                  </div>
                </section>
                <section className="contents-container__grid">
                  <div className="contents-container__grid-index"></div>
                  <div className="contents-container__grid-contents">
                    <div className="minmax300">
                      <span className="font14"></span>
                    </div>
                  </div>
                </section>
              </div>
            </article>
          </div>
          {/* 버튼영역 */}
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title="목록으로"
                className="color-white size-large"
                onClick={() => {
                  if (id) {
                    // 수정화면에서는 목록으로 바로이동
                    navigate(PagePath.accessGroup.list);
                  } else {
                    // 등록화면에서는 목록에서 진입했기 때문에 뒤로가기
                    navigate(-1);
                  }
                }}
              />
              <BaseButton
                title="삭제"
                className="no-outline color-white size-large text-red ml10"
                onClick={() =>
                  setConfirmModal({
                    isOpen: true,
                    type: "CONFIRM_DELETE",
                  })
                }
              />
            </div>
            <div className="right-area">
              <BaseButton
                title="수정"
                className="size-large"
                onClick={() => {
                  const formPath = `${PagePath.accessGroup.form}?id=${id}`;
                  navigate(formPath);
                }}
              />
            </div>
          </div>

          {/* 취소/확인 모달 */}
          <BaseModal
            isOpen={confirmModal.isOpen}
            btnLeftTitle="취소"
            btnRightTitle="확인"
            onClose={() => setConfirmModal({ isOpen: false })}
            onClick={() => {
              confirmModal.type === "CONFIRM_DELETE" && handelEraseConfirm();
              confirmModal.type === "SUCCESS_DELETE" && navigate(PagePath.accessGroup.list);
            }}
          >
            <>
              {confirmModal.type === "CONFIRM_DELETE" && <p>삭제 하시겠습니까?</p>}
              {confirmModal.type === "SUCCESS_DELETE" && <p>삭제 되었습니다.</p>}
            </>
          </BaseModal>

          {/* 확인버튼만 있는 alert 모달 */}
          <BaseModal
            isOpen={alertModal.isOpen}
            btnRightTitle="확인"
            onClick={() => setAlertModal({ isOpen: false })}
          >
            <p>{alertModal.message}</p>
          </BaseModal>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
