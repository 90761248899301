import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccessGroupListAsync } from "src/api/access/ac2-api";
import { AccessGroupListParams, AccessGroupModel } from "src/api/access/ac2-types";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta } from "src/api/public-types";
import { BaseButton, BaseInput, BaseTable, ContentsTitle } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import AccessGroupListColumns from "./columns/AccessGroupListColumns";
import { getPartner } from "src/api/partner";
import { Partner } from "src/types/partner";
import PartnerSelectModal from "src/components/partner/PartnerSelectModal";

// 검색 대상
const searchTargets = [
  { value: "", label: "전체" },
  { value: "accessGroupName", label: "출입그룹 이름" },
];

// 정렬기능 숨김
const disabledSortHeaders: string[] = ["externalServerNames", "deviceIds", "partnerIds"];

// url query parameter 타입
type QueryParams = AccessGroupListParams & {
  page?: number;
  size?: number;
  accessGroupName?: string;
  searchType?: string;
  searchValue?: string;
  partnerId?: number;
  partnerName?: string;
  sort?: {
    orders?: Array<Order>;
  };
};

/*
  출입그룹 관리 목록 화면
*/
const AccessGroupList = () => {
  const { setLoadingBar } = useLoadingBarContext();
  const navigate = useNavigate();
  const location = useLocation();
  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "createdDate", direction: "DESC" }],
      };
    }
    return _queryParams;
  }, [location]);

  const [accessGroups, setAccessGroup] = useState<AccessGroupModel[]>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [params, setParams] = useState<QueryParams>({ ...queryParams });
  const [selectedPartners, setSelectedPartners] = useState<Partner[]>([]);
  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState<boolean>(false);

  const { executeAsync: getPartnerAsync } = useApiOperation(getPartner);
  const { executeAsync: getAccessGroupList } = useApiOperation(getAccessGroupListAsync);

  const onAddSelectPartners = (partners: Partner[]) => {
    setSelectedPartners(partners);
    setIsPartnerModalOpen(false);
    setParams({
      ...params,
      partnerId: Number(partners[0].id),
    });
  };

  useEffect(() => {
    async function fetchApi(params: QueryParams) {
      setLoadingBar(true);
      const fetchPartner = async (id: string) => {
        const response = await getPartnerAsync({ id });
        if (response.status >= 200 && response.status <= 299) {
          const partner = response.data.data.partner;
          setSelectedPartners([partner]);
        }
      };

      // 출입그룹 목록 조회
      const { data: accessGroupList } = await getAccessGroupList(params);
      const accessGroupData = accessGroupList?.data?.content;
      if (params.partnerId) {
        // 파트너 조회
        await fetchPartner(String(params.partnerId));
      }

      setAccessGroup(accessGroupData);
      setPageMeta(accessGroupList?.meta?.pageMeta);
      setLoadingBar(false);
    }
    fetchApi(queryParams);
  }, [queryParams, setLoadingBar, getAccessGroupList, getPartnerAsync]);

  const findDirection = () => {
    const findDirection = params?.sort?.orders?.find(
      (order: { property: string; direction: string }) => order.property === "createdDate",
    );
    return findDirection?.direction;
  };

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }
      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  useEffect(() => {
    findDirection();
  });

  return (
    <div className="page-product-list">
      {/* <ContentsTitle title="출입그룹 관리" /> */}
      <div className="contents-container__search-wrap">
        <div className="left-area">
          {/* <p>
              전체 <span>{pageMeta?.totalElements || 0}</span>
            </p> */}
          <div className="minmax200 mr8">
            <BaseInput
              readonly
              placeholder="파트너"
              value={selectedPartners[0]?.name || params.partnerName}
              onSearchClick={() => setIsPartnerModalOpen(true)}
              onClearClick={() => {
                setSelectedPartners([]);
                const { partnerId, partnerName, ...rest } = params;
                setParams(rest);
              }}
            />
          </div>
          <BaseInput
            value={params?.searchValue || ""}
            className="minmax220 mr16"
            type="text"
            placeholder="검색어를 입력해주세요"
            onChange={(searchValue: string) => {
              setParams({ ...params, searchValue });
            }}
            onKeyUp={() => navigateWithQueryParams({ page: 0 }, "search")}
            onSearchClick={() => navigateWithQueryParams({ page: 0 }, "search")}
          />
        </div>
        <div className="right-area">
          <BaseButton title="+ 출입그룹 등록" onClick={() => navigate(PagePath.accessGroup.form)} />
        </div>
      </div>

      {isPartnerModalOpen && (
        <PartnerSelectModal
          onCanceled={() => {
            setIsPartnerModalOpen(false);
          }}
          onAdded={onAddSelectPartners}
          defaultValues={selectedPartners || []}
        />
      )}

      <BaseTable
        data={accessGroups}
        columns={AccessGroupListColumns}
        pageIndex={Number(params?.page || 0)}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page }, "pagination");
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={disabledSortHeaders}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } }, "search");
          }
        }}
      />
    </div>
  );
};

export default AccessGroupList;
