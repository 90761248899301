import moment from "moment";

// modal 공통
export type Modal = {
  isOpen: boolean;
  message?: string;
  type?: string;
  payload?: any;
};

export const ReservationState = {
  ACCEPTED: "ACCEPTED", // 접수
  ACKNOWLEDGED: "ACKNOWLEDGED", // 점유
  INCOMPLETE: "INCOMPLETE", // 불완전
  CANCELLED: "CANCELLED", // 취소
} as const;

//  예약초대 상태
export const ReservationAttendeeResponseStatus = {
  NONE: "NONE", //응답없음
  DECLINED: "DECLINED", //거부
  TENTATIVE: "TENTATIVE", // 임시적 수락
  ACCEPTED: "ACCEPTED", //수락
};

// 공용공간 예약 status 한글 변환
export const getStatusText = (status?: string, endDate?: Date) => {
  const accepted = ReservationState.ACCEPTED;
  const cancelled = ReservationState.CANCELLED;
  const acknowledged = ReservationState.ACKNOWLEDGED;
  const incomplete = ReservationState.INCOMPLETE;
  switch (status) {
    case accepted:
      return "접수";
    case acknowledged:
      return "점유";
    case incomplete:
      return "불완전";
    case cancelled:
      return "취소";
    default:
      return "-";
  }
};

export const ExternalServiceType = {
  EXTERNALSERVICE_UNRECOGNIZED: "EXTERNALSERVICE_UNRECOGNIZED", //정의되지 않은 타입
  TAAP: "TAAP",
  GC: "GC", // Google Calendar
  OC: "OC", //Outlook Calendar
  CTRL_ROOM: "CTRL_ROOM",
  TAAP_SPACE: "TAAP_SPACE",
} as const;

export type ReservationExternalTypes = "TAAP" | "GC" | "OC";

// 공용공간예약 엑셀 다운로드 헤더
export const reservationHeaders = [
  { label: "id", key: "id" },
  { label: "group id", key: "reservationGroupId" },
  { label: "상품 id", key: "productId" },
  { label: "신청/계약 번호", key: "contractApplyNumber" },
  { label: "예약경로", key: "externalServiceType" },
  { label: "건물명", key: "buildingName" },
  { label: "위치 (층)", key: "buildingCommonFacilityFloorNum" },
  { label: "위치 (지상여부)", key: "buildingCommonFacilityIsGround" },
  { label: "위치 (id)", key: "buildingCommonFacilityId" },
  { label: "위치 (LocationCode)", key: "buildingCommonFacilityLocationCode" },
  { label: "공간타입", key: "commonFacilityType" },
  { label: "공간명", key: "buildingCommonFacilityName" },
  { label: "예약기간 (시작일시)", key: "start" },
  { label: "예약기간 (종료일시)", key: "end" },
  { label: "예약자 (회원번호)", key: "memberNo" },
  { label: "예약자 (이메일)", key: "memberEmail" },
  { label: "예약 제목", key: "summary" },
  { label: "생성일", key: "createdDate" },
  { label: "상태", key: "status" },
];

// 공용공간예약 소진시간 엑셀 다운로드 헤더
export const reservationUsageHeaders = [
  { label: "id", key: "reservationId" },
  { label: "신청/계약 번호", key: "contract.applyNumber" },
  { label: "건물명", key: "facility.building.name" },
  { label: "공간타입", key: "facility.type" },
  { label: "공간id", key: "facility.id" },
  { label: "공간명", key: "facility.name" },
  { label: "예약기간 (시작일시)", key: "start" },
  { label: "예약기간 (종료일시)", key: "end" },
  { label: "예약자 (회원번호)", key: "organizer.memberNo" },
  { label: "예약자 (휴대폰번호)", key: "organizer.phoneNumber" },
  { label: "예약자 (이메일)", key: "organizer.userEmail" },
];

export const reservationStatuses = [
  { value: "", label: "전체" },
  { value: "RESERVATION_FAILED", label: "예약실패" },
  { value: "WAITING_FOR_USE", label: "이용대기" },
  { value: "IN_USE", label: "이용중" },
  { value: "USE_COMPLETED", label: "이용완료" },
  { value: "USE_CANCELLED", label: "이용취소" },
];

// 공용공간 예약상태 프론트에서 사용하는 value 값 반환
export const getStatusValue = (statusCode?: string, startDate?: string, endDate?: string) => {
  if (statusCode === ReservationState.INCOMPLETE) {
    return "RESERVATION_FAILED";
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment().isBefore(moment(startDate))) {
    return "WAITING_FOR_USE";
  } else if (
    statusCode === ReservationState.ACKNOWLEDGED &&
    moment().isBetween(moment(startDate), moment(endDate), null, "[)")
  ) {
    return "IN_USE";
  } else if (
    statusCode === ReservationState.ACKNOWLEDGED &&
    moment(endDate).isSameOrBefore(moment())
  ) {
    return "USE_COMPLETED";
  } else if (statusCode === ReservationState.CANCELLED) {
    return "USE_CANCELLED";
  } else return "-";
};
export const getStatusTextAndColor = (
  statusCode?: string,
  startDate?: string,
  endDate?: string,
) => {
  if (statusCode === ReservationState.INCOMPLETE) {
    return { label: "예약실패", color: "status C" };
  } else if (statusCode === ReservationState.ACKNOWLEDGED && moment().isBefore(moment(startDate))) {
    return { label: "이용대기", color: "status A" };
  } else if (
    statusCode === ReservationState.ACKNOWLEDGED &&
    moment().isBetween(moment(startDate), moment(endDate), null, "[)")
  ) {
    return { label: "이용중", color: "status B" };
  } else if (
    statusCode === ReservationState.ACKNOWLEDGED &&
    moment(endDate).isSameOrBefore(moment())
  ) {
    return { label: "이용완료", color: "status D" };
  } else if (statusCode === ReservationState.CANCELLED) {
    return { label: "이용취소", color: "status D" };
  } else return { label: "", color: "" };
};
