import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getUserAccessAsync,
  getUserCardAsync,
  postUserCardAsync,
  putUserActiveAsync,
  putUserDeactiveAsync,
} from "src/api/access/ac-api";
import { CardModel, UserAccessModel } from "src/api/access/ac-types";
import {
  deleteFixedCsnAsync,
  deleteFixedFaceAsync,
  getFixedCsnAsync,
  getFixedFaceAsync,
  updateFixedCsnAsync,
} from "src/api/access/ac2-api";
import { AccessCodeModel } from "src/api/access/ac2-types";
import { useApiOperation } from "src/api/hooks";
import { getMemberDetail } from "src/api/member/member-api";
import { MemberModel } from "src/api/member/member-types";
import { BaseButton, BaseInput, BaseModal, ContentsIdSection } from "src/components";
import SearchAccessLog from "src/components/access/SearchAccessLog";
import { Modal } from "src/pages/building/building-types";
import { useLoadingBarContext, useToastContext } from "src/pages/hooks";
import AccessGroupDetailPopup from "src/pages/product/components/AccessGroupDetailPopup";
import { PagePath } from "src/pages/product/details";

const MemberAccessInfoV2 = ({}) => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  // 토스트
  const { openToast } = useToastContext();

  // 네비게이션
  const navigate = useNavigate();
  const location = useLocation();

  const param = useParams();
  const memberId = param.id;

  // 회원 상세
  const [basicInfo, setBasicInfo] = useState<MemberModel>();

  // 회원액세스 정보
  const [userAccess, setUserAccess] = useState<UserAccessModel | undefined>();

  // CSN 상세
  const [userCard, setUserCard] = useState<CardModel | undefined>();

  // 고정 CSN 상세
  const [fixesUserCard, setFixedUserCard] = useState<AccessCodeModel | undefined>();

  // 회원액세스 정보 api hook
  const { executeAsync: getUserAccess } = useApiOperation(getUserAccessAsync);

  // CSN 상세 api hook
  const { executeAsync: getUserCard } = useApiOperation(getUserCardAsync);

  // 권한 업데이트 api hook
  // const { executeAsync: syncUserAccess } = useApiOperation(putUserSyncAsync);

  // CNS 재발급 api hook
  const { executeAsync: renewUserCard } = useApiOperation(postUserCardAsync);

  // 회원액세스 활성화
  const { executeAsync: activeUser } = useApiOperation(putUserActiveAsync);

  // 회원액세스 비활성화
  const { executeAsync: deactiveUser } = useApiOperation(putUserDeactiveAsync);

  //회원 상세조회 API
  const { executeAsync: getMemberDetailAsync } = useApiOperation(getMemberDetail);

  // 고정 CSN 조회 (ac2)
  const { executeAsync: getFixedCsnDetail } = useApiOperation(getFixedCsnAsync);

  // 고정 CSN 등록 (ac2)
  const { executeAsync: updateFixedCsn } = useApiOperation(updateFixedCsnAsync, {
    doNotErrorHandleModal: true,
  });
  // 고정 CSN 삭제 (ac2)
  const { executeAsync: deleteFixedCsn } = useApiOperation(deleteFixedCsnAsync);

  // 얼굴인식 조회 (ac2)
  const { executeAsync: getFixedFace } = useApiOperation(getFixedFaceAsync);

  // 얼굴인식 삭제 (ac2)
  const { executeAsync: deleteFixedFace } = useApiOperation(deleteFixedFaceAsync);

  // 취소, 확인 버튼이 있는 confirm 모달
  const [confirmModal, setConfirmModal] = useState<Modal>({
    isOpen: false,
  });

  // 확인버튼만 있는 alert 모달
  const [alertModal, setAlertModal] = useState<Modal>({
    isOpen: false,
  });

  // 전체 출입내역 팝업 (ac2)
  const [isAccessLogModalOpen, setIsAccessLogModalOpen] = useState<boolean>(false);
  const [fixedFace, setFixedFace] = useState<AccessCodeModel>();

  const validateCSN = (value: string) => {
    // 숫자와 알파벳만 허용하는 정규 표현식 최대 50자
    const pattern = /^[a-zA-Z0-9]{0,50}$/;
    return pattern.test(value);
  };
  // Modal 확인 버튼 클릭
  const clickModalConfirm = async () => {
    let memberNo = basicInfo?.memberNo;

    // 모달 닫기
    setConfirmModal({ isOpen: false });

    // 권한 업데이트
    // if (confirmModal.type === "SYNC_USER") {
    //   await syncUserAccess({ memberNo: String(memberNo) });
    //   openToast(`정상적으로 업데이트 되었습니다.`);
    //   await fetchUserAccess(String(memberNo));
    // }

    // CSN 재발급
    if (confirmModal.type === "RENEW_CARD") {
      await renewUserCard({ memberNo: String(memberNo) });
      openToast(`정상적으로 재발급 되었습니다.`);
      // await fetchUserAccess(String(memberNo));
      await fetchUserCard(String(memberNo));
    }

    // 고정 CSN 발급
    if (confirmModal.type === "FIXED_CARD") {
      if (fixesUserCard && fixesUserCard.accessToken && fixesUserCard?.accessToken !== "") {
        const response: any = await updateFixedCsn({
          memberNo: String(memberNo),
          accessToken: fixesUserCard?.accessToken,
        });
        if (response.status >= 200 && response.status <= 299) {
          openToast(`정상적으로 업데이트 되었습니다.`);
          await callFixedData(String(memberNo));
        } else {
          if (response.data.meta.errorCode === "eAC105") {
            setAlertModal({
              isOpen: true,
              message: "이미 시스템 내에 존재하는 CSN입니다. CSN을 다시 확인하여 주세요",
            });
          } else if (response.data.meta.errorCode === "eAC401") {
            setAlertModal({
              isOpen: true,
              message: "사용자를 찾을 수 없습니다. : " + response?.data?.meta?.errorMessage,
            });
          } else {
            setAlertModal({
              isOpen: true,
              message: "오류가 발생하였습니다. : " + response?.data?.meta?.errorMessage,
            });
          }
        }
      } else {
        setAlertModal({
          isOpen: true,
          message: "CSN 값은 숫자 8~15자만 허용됩니다.",
        });
      }
    }

    // 고정 CSN 삭제
    if (confirmModal.type === "FIXED_CARD_DELETE") {
      if (fixesUserCard && fixesUserCard.accessToken && fixesUserCard?.accessToken !== "") {
        await deleteFixedCsn({ memberNo: String(memberNo) });
        openToast(`정상적으로 삭제 되었습니다.`);
        await callFixedData(String(memberNo));
      }
    }

    // 상태 변경
    if (confirmModal.type === "TOGGLE_DISABLED") {
      let disabled = confirmModal.payload as boolean;
      if (disabled) {
        await deactiveUser({ memberNo: String(memberNo) });
        openToast(`비활성화 되었습니다`);
      } else {
        await activeUser({ memberNo: String(memberNo) });
        openToast(`활성화 되었습니다`);
      }

      // await fetchUserAccess(String(memberNo));
    }

    // 얼굴 인식 이미지 삭제
    if (confirmModal.type === "REMOVE_FACE") {
      if (fixedFace && fixedFace.id) {
        await deleteFixedFace({ memberNo: String(memberNo) });
        openToast(`정상적으로 삭제 되었습니다.`);
        await fetchFixedFace(String(memberNo));
      }
    }
  };

  // 회원 상세 api
  const getMemberDetailApi = useCallback(async (id: string) => {
    if (memberId) {
      const response = await getMemberDetailAsync({ id });
      if (response.status >= 200 && response.status <= 299) {
        const memberData = response?.data?.data?.member;

        setBasicInfo(memberData || null);
      }
    }
  }, []);
  // useEffect(() => {
  //   getMemberDetailApi(String(memberId));
  // }, [getMemberDetailApi, memberId]);

  const fetchUserAccess = useCallback(
    async (memberNo: string) => {
      const { data } = await getUserAccess({ memberNo });
      setUserAccess(data?.data?.user || null);
    },
    [getUserAccess],
  );

  const fetchUserCard = useCallback(
    async (memberNo: string) => {
      const { data } = await getUserCard({ memberNo });
      setUserCard(data?.data?.qr || null);
    },
    [getUserCard],
  );

  const callFixedData = useCallback(
    async (memberNo: string) => {
      const { data } = await getFixedCsnDetail({ memberNo });
      setFixedUserCard(data?.data?.accessCode || {});
    },
    [getFixedCsnDetail],
  );

  const fetchFixedFace = useCallback(
    async (memberNo: string) => {
      const { data } = await getFixedFace({ memberNo });
      setFixedFace(data?.data?.accessCode || {});
    },
    [getFixedFace],
  );

  // 출입그룹 상세 모달
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

  // 출입그룹 상세 키값
  const [accessGroupId, setAccessGroupId] = useState(0);
  const [apiServerId, setApiServerId] = useState(0);
  useEffect(() => {
    getMemberDetailApi(String(memberId));
    if (basicInfo?.memberNo) {
      let memberNo = basicInfo.memberNo;
      fetchFixedFace(memberNo);
      fetchUserCard(memberNo);
      callFixedData(memberNo);
    }
  }, [
    basicInfo?.memberNo,
    getMemberDetailApi,
    fetchUserAccess,
    fetchUserCard,
    callFixedData,
    fetchFixedFace,
    memberId,
    setLoadingBar,
  ]);
  return (
    <div className="contents-container__wrap detail-usage-restriction">
      <div className="contents-container__wrap-contents">
        <ContentsIdSection
          title="액세스 권한"
          id={basicInfo?.id}
          noTitle="회원번호"
          no={basicInfo?.memberNo}
        />
        <section>
          <div className="contents-container__grid mb10">
            <div className="contents-container__grid-index">
              <p>CSN</p>
            </div>
            <div className="contents-container__grid-contents pl20">
              <div className="flex-row flex-center-start">
                <p>{userCard?.cardSerialNumber}</p>
                {
                  <BaseButton
                    title="CSN 재발급"
                    className={`color-block ${userCard?.cardSerialNumber ? "ml18" : ""}`}
                    onClick={() => {
                      setConfirmModal({
                        isOpen: true,
                        message: "정말 업데이트 하시겠습니까?",
                        type: "RENEW_CARD",
                        payload: "MANUAL",
                      });
                    }}
                  />
                }
              </div>
            </div>
          </div>
          <div className="contents-container__grid mb10">
            <div className="contents-container__grid-index">
              <p>CSN 생성일시</p>
            </div>
            <div className="contents-container__grid-contents pl20">
              <div>
                {userCard && <p>{moment(userCard?.creationDate).format("YYYY-MM-DD HH:mm:ss")}</p>}
              </div>
            </div>
          </div>
          <div className="contents-container__grid mb10">
            <div className="contents-container__grid-index">
              <p>고정 CSN</p>
            </div>
            <div className="contents-container__grid-contents pl20">
              <div className="flex-row flex-center-start">
                {fixesUserCard && fixesUserCard.id === undefined ? (
                  <>
                    <div className="minmax300">
                      <BaseInput
                        name="fixedCsn"
                        type="text"
                        value={fixesUserCard?.accessToken}
                        onChange={(value: string) => {
                          if (value.length <= 50) {
                            if (validateCSN(value)) {
                              const temp = _.cloneDeep(fixesUserCard);
                              temp.accessToken = value;
                              setFixedUserCard(temp);
                            }
                          }
                        }}
                      />
                    </div>
                    <BaseButton
                      title="등록"
                      className="color-block ml10"
                      onClick={() => {
                        if (
                          fixesUserCard &&
                          fixesUserCard.accessToken &&
                          fixesUserCard.accessToken?.length > 5 &&
                          fixesUserCard.accessToken?.length < 51
                        ) {
                          setConfirmModal({
                            isOpen: true,
                            message: "고정 CSN을 등록 하시겠습니까?",
                            type: "FIXED_CARD",
                            payload: "MANUAL",
                          });
                        } else {
                          setAlertModal({
                            isOpen: true,
                            message: "CSN 값은 숫자와 알파벳 6~50자만 허용됩니다.",
                          });
                        }
                      }}
                    />
                  </>
                ) : (
                  <>
                    <p>{fixesUserCard?.accessToken}</p>

                    <BaseButton
                      title="삭제"
                      className="color-block ml18"
                      onClick={() => {
                        setConfirmModal({
                          isOpen: true,
                          message: "고정 CSN을 삭제 하시겠습니까?",
                          type: "FIXED_CARD_DELETE",
                          payload: "MANUAL",
                        });
                      }}
                    />
                    {/* <BaseButton
                      title="재동기화"
                      className="color-block ml10"
                      onClick={() => {
                        setConfirmModal({
                          isOpen: true,
                          message: "고정 CSN을 재동기화 하시겠습니까?",
                          type: "FIXED_CARD",
                          payload: "MANUAL",
                        });
                      }}
                    /> */}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="contents-container__grid mb10">
            <div className="contents-container__grid-index">
              <p>고정 CSN 생성일시</p>
            </div>
            <div className="contents-container__grid-contents pl20">
              <div>
                {fixesUserCard?.createdDate && (
                  <p>{moment(fixesUserCard?.createdDate).format("YYYY-MM-DD HH:mm:ss")}</p>
                )}
              </div>
            </div>
          </div>
          <div className="contents-container__grid mb10">
            <div className="contents-container__grid-index">
              <p>얼굴 인식 연동</p>
            </div>
            <div className="contents-container__grid-contents pl20">
              <div>
                {fixedFace?.accessToken && (
                  <div className="flex-center">
                    <p>등록</p>
                    <BaseButton
                      title="삭제"
                      className="color-block ml18"
                      onClick={() => {
                        setConfirmModal({
                          isOpen: true,
                          message: "얼굴 인식 이미지를 삭제하시겠습니까?",
                          type: "REMOVE_FACE",
                          payload: "MANUAL",
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="contents-container__grid mb10">
            <div className="contents-container__grid-index">
              <p>얼굴 인식 연동 등록일시</p>
            </div>
            <div className="contents-container__grid-contents pl20">
              <div>
                {fixedFace?.createdDate && (
                  <p>{moment(fixedFace?.createdDate).format("YYYY-MM-DD HH:mm:ss")}</p>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* 출입 내역(최근 7일) */}
        <section className="contents-container__divide-top">
          <div className="contents-container__sub-title">
            <h2>출입 내역</h2>
            <BaseButton
              title="전체 출입 내역"
              className="color-white"
              onClick={() => setIsAccessLogModalOpen(true)}
            />
            {/* {basicInfo?.memberNo && (
              <MemberEventListModal
                memberNo={basicInfo.memberNo}
                showModal={showEventModal}
                setShowModal={setShowEventModal}
              />
            )} */}
            {basicInfo?.memberNo && isAccessLogModalOpen && (
              <SearchAccessLog
                isOpen={true}
                memberId={basicInfo?.memberNo}
                onClose={() => setIsAccessLogModalOpen(false)}
              />
            )}
          </div>
        </section>
      </div>
      <div className="contents-container__btn-wrap">
        <div className="left-area">
          <BaseButton
            title="목록으로"
            onClick={() => navigate(PagePath.member.list)}
            className="color-white size-large"
          />
        </div>
        <div className="right-area">{/* <BaseButton title="수정" className="size-large" /> */}</div>
      </div>

      {/* 취소, 확인 버튼이 있는 confirm 모달 */}
      <BaseModal
        isOpen={confirmModal.isOpen}
        btnLeftTitle="취소"
        btnRightTitle="확인"
        onClose={() => setConfirmModal({ isOpen: false })}
        onClick={() => clickModalConfirm()}
      >
        <p>{confirmModal.message}</p>
      </BaseModal>

      {/* 확인버튼만 있는 alert 모달 */}
      <BaseModal
        isOpen={alertModal.isOpen}
        btnRightTitle="확인"
        onClick={() => setAlertModal({ isOpen: false })}
      >
        <p>{alertModal.message}</p>
      </BaseModal>

      <AccessGroupDetailPopup
        showModal={showDetailModal}
        setShowModal={setShowDetailModal}
        accessGroupId={accessGroupId}
        apiServerId={apiServerId}
      />
    </div>
  );
};
export default MemberAccessInfoV2;
